<section class="beneficios" id="beneficios">
  <div class="container">
    <div class="row">
      <div class="coluna-1 col-sm-12 col-lg-6">
        <h6>Benefícios</h6>
        <h2>Porque somos únicos?</h2>
        <p>
          O nosso compromisso com a empatia nos faz colocar os usuários da
          plataforma no centro das nossas decisões, o paciente no centro do
          cuidado e o médico no centro de uso. Conheça nossos principais
          benefícios:
        </p>
        <ul>
          <li *ngFor="let beneficio of beneficios">
            <img src="assets/images/icones/check.svg" />
            <p>{{ beneficio }}</p>
          </li>
        </ul>
      </div>
      <div class="coluna-2 col-sm-12 col-lg-6">
        <img
          src="assets/images/mockups/mockup-beneficios.png"
          class="img-fluid"
          alt="Benefícios"
        />
      </div>
    </div>
  </div>
</section>
