import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ModalOrcamentoSolicitadoComponent } from './modal-orcamento-solicitado.component';
import { ModalModule } from 'ngx-bootstrap/modal';



@NgModule({
  declarations: [
    ModalOrcamentoSolicitadoComponent
  ],
  imports: [
    CommonModule,
    ModalModule.forRoot()
  ],
  exports: [
    ModalOrcamentoSolicitadoComponent
  ]
})
export class ModalOrcamentoSolicitadoModule { }
