<section id="perguntas" class="perguntas">
  <div class="container">
    <div class="row">
      <div class="coluna-1 col-sm-12 col-lg-6">
        <h2 *ngIf="width < 992">Tem dúvidas?</h2>
        <img
          src="assets/images/ilustracoes/duvidas.svg"
          class="img-fluid"
          alt="Você tem dúvidas?"
        />
        <h2 *ngIf="width >= 992">Você tem dúvidas?</h2>
        <p>Confira as perguntas frequentes</p>
      </div>
      <div class="coluna-2 col-sm-12 col-lg-6">
        <div class="pergunta" *ngFor="let pergunta of perguntas">
          <a
            data-toggle="collapse"
            href="#{{ pergunta.id }}"
            role="button"
            aria-expanded="false"
            aria-controls="collapse"
            (click)="open($event, pergunta)"
          >
            <div class="topico">
              <img
                src="{{
                  pergunta.collapsed
                    ? 'assets/images/icones/menos-preto.svg'
                    : 'assets/images/icones/mais-preto.svg'
                }}"
              />
              <p>{{ pergunta.title }}</p>
            </div>
          </a>
          <div class="collapse" id="{{ pergunta.id }}">
            <div class="conteudo">
              {{ pergunta.content }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
