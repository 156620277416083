import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-para-quem',
  templateUrl: './para-quem.component.html',
  styleUrls: ['./para-quem.component.scss'],
})
export class ParaQuemComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
