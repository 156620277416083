<div class="modal fade" id="modal-termos" tabindex="-1">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="botao-fechar">
        <button class="close" data-dismiss="modal" aria-label="Close"
          ><img src="assets/images/icones/botao-fechar.svg"
        /></button>
      </div>
      <div class="texto">
        <h1>Termos e Condições de Utilização</h1>

        <h4>Teste</h4>

        <p>
          Nossa equipe de consultores irá avaliar a sua solicitação e, muito
          em breve, você receberá o valor por e-mail.
        </p>
      </div>

      <button
        class="btn-outline-lg btn-outline-lg-secondary"
        data-dismiss="modal" aria-label="Close"
      >
        Fechar
      </button>
    </div>
  </div>
</div>
