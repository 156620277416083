import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-preco',
  templateUrl: './preco.component.html',
  styleUrls: ['./preco.component.scss'],
})
export class PrecoComponent implements OnInit {
  itens = [
    'Perfil público do médico',
    'Agendamento online',
    'Notificações automatizadas',
    'App mobile',
    'Prontuário online com vídeo',
    'Termo de Consentimento automatizado',
    'Integração com a Memed para prescrições digitais',
    'Telemonitoramento (em breve)',
  ];
  tipo = 'anual';
  quantidade = 1;
  planos = [
    { preco: 'R$ 59,90', economia: 'Economize R$120 ao ano' },
    { preco: 'R$ 119,80', economia: 'Economize R$240 ao ano' },
    { preco: 'R$ 179,70', economia: 'Economize R$360 ao ano' },
    { preco: 'R$ 239,60', economia: 'Economize R$480 ao ano' },
    { preco: 'R$ 299,50', economia: 'Economize R$600 ao ano' },
    { preco: 'R$ 359,40', economia: 'Economize R$719 ao ano' },
    { preco: 'R$ 419,30', economia: 'Economize R$839 ao ano' },
    { preco: 'R$ 479,20', economia: 'Economize R$960 ao ano' },
    { preco: 'R$ 539,10', economia: 'Economize R$1079 ao ano' },
    { preco: 'R$ 599,00', economia: 'Economize R$1200 ao ano' },
    { preco: 'Valor sob consulta', economia: 'Receba o valor por e-mail' },
  ];

  constructor() {}

  ngOnInit(): void {
    this.changePlan('anual');
  }

  changeSlider() {
    let valor = document.querySelector('.valor');
    let slider = document.querySelector('input');

    this.quantidade = Number(slider.value);

    if (this.quantidade === this.planos.length) {
      valor.classList.add('sob-consulta');
    } else {
      valor.classList.remove('sob-consulta');
    }
  }

  increment() {
    let valor = document.querySelector('.valor');

    if (this.quantidade < this.planos.length) {
      this.quantidade = this.quantidade + 1;
    }

    if (this.quantidade === this.planos.length) {
      valor.classList.add('sob-consulta');
    }
  }

  decrement() {
    let valor = document.querySelector('.valor');

    if (this.quantidade > 1) {
      this.quantidade = this.quantidade - 1;
    }

    valor.classList.remove('sob-consulta');
  }

  changePlan(plan) {
    let anual = document.querySelector('.anual');
    let mensal = document.querySelector('.mensal');
    this.tipo = plan;

    if (plan === 'anual') {
      anual.classList.add('tipo');
      mensal.classList.remove('tipo');
    } else {
      mensal.classList.add('tipo');
      anual.classList.remove('tipo');
    }
  }
}
