import { Component, HostListener, OnInit } from '@angular/core';

@Component({
  selector: 'app-conhecendo',
  templateUrl: './conhecendo.component.html',
  styleUrls: ['./conhecendo.component.scss'],
})
export class ConhecendoComponent implements OnInit {
  height: number;
  width: number;

  constructor() {}

  ngOnInit(): void {
    this.detectScreenSize();
  }

  @HostListener('window:resize', [])
  public onResize() {
    this.detectScreenSize();
  }

  private detectScreenSize() {
    this.height = window.innerHeight;
    this.width = window.innerWidth;
  }
}
