import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-humanizacao',
  templateUrl: './humanizacao.component.html',
  styleUrls: ['./humanizacao.component.scss']
})
export class HumanizacaoComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
