<section id="prescricao" class="prescricao mt-5 pt-5">
    <div class="container">
        <div class="row session-head">
            <div class="col-lg-12 text-center">
                <h6 class="session-subtitle">ESTAMOS INTEGRADOS COM A MEMED</h6>
                <h2 class="session-title">
                    Prescrição online
                </h2>
            </div>
        </div>

        <div class="row">
            <div class="col-lg-6">
                <img src="assets\images\mockups\memed-prescricao-2.png" class="img-fluid">
            </div>

            <article class="col-lg-6">
                <p class="mt-5">Prescreva de maneira simples e rápida, com a melhor ferramenta de prescrição médica do país. Sim, estamos integrados com a Memed.<br>
                <a class="btn-link" href="https://www.youtube.com/watch?v=iIw7b0z-xqU" target="_blank">
                    <i class="fa fa-youtube"></i> Veja como é simples prescrever pela Memed
                </a>
                </p>
                <h6>Assinatura digital</h6>
                <p>É importante lembrar que as receitas digitais só são válidas mediante uma assinatura digital emitida por um certificado válido, no padrão ICP-Brasil. Se você ainda não tem uma assinatura eletrônica, você pode adquirir com 50% de desconto, através da parceria Memed & Soluti no link abaixo:
                    <br>
                    <a href="https://conteudo.soluti.com.br/soluti-memed" target="_blank">   
                        Adquirir certificado digital Soluti com 50% de desconto
                    </a>
                </p>
            </article>
        </div>
    </div>
</section>
