import { Component, OnInit } from '@angular/core';
import { Instruction } from '../band-handbook.component.types';

@Component({
  selector: 'app-vincule',
  templateUrl: './vincule.component.html',
  styleUrls: ['./vincule.component.scss'],
})
export class VinculeComponent implements OnInit {
  instructions: Instruction[][] = [];
  instructionIndex = 0;

  goToNextSection() {
    this.animate(true);

    setTimeout(() => {
      if (this.instructionIndex === this.instructions.length - 1) {
        return;
      }

      this.animate(false);

      this.instructionIndex += 1;
    }, 330);
  }

  goToPreviousSection() {
    this.animate(true);

    setTimeout(() => {
      if (this.instructionIndex === 0) {
        return;
      }

      this.animate(false);

      this.instructionIndex -= 1;
    }, 330);
  }

  animate(activated: boolean) {
    let element = document.getElementById('instruction-section');

    if (!element) {
      return;
    }

    if (activated) {
      element.classList.add('fadeOut');
      element.classList.remove('fadeIn');
    } else {
      element.classList.add('fadeIn');
      element.classList.remove('fadeOut');
    }
  }

  constructor() {}

  ngOnInit(): void {
    this.instructions = [
      [
        {
          stepNumber: 1,
          description:
            'Após instalar o aplicativo no seu Smartphone, toque em “Cadastrar” em seu primeiro acesso.',
        },
        {
          stepNumber: 2,
          description: 'Digite o seu CPF e complete o cadastro.',
        },
      ],
      [
        {
          stepNumber: 3,
          description:
            'Permita que o seu celular ative o Bluetooth e aguarde que ele localize a pulseira de saúde (localize o dispositivo com o nome "CINCO + numeração correspondente a sua pulseira").',
        },
        {
          stepNumber: 4,
          description: 'Toque no botão selecionar',
        },
      ],
    ];
  }
}
