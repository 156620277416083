import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-band-handbook',
  templateUrl: './band-handbook.component.html',
  styleUrls: ['./band-handbook.component.scss'],
})
export class BandHandbookComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
