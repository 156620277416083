import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ajuste',
  templateUrl: './ajuste.component.html',
  styleUrls: ['./ajuste.component.scss'],
})
export class AjusteComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
