import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Orcamento } from './DTOs/Orcamento';
import { OrcamentoService } from './orcamento.service';
import { ModalOrcamentoSolicitadoComponent } from './modal-orcamento-solicitado/modal-orcamento-solicitado.component'

@Component({
  selector: 'app-orcamento',
  templateUrl: './orcamento.component.html',
  styleUrls: ['./orcamento.component.scss']
})

export class OrcamentoComponent implements OnInit {
  orcamentoForm = new FormGroup({
    name: new FormControl('', [Validators.required]),
    email: new FormControl('', [Validators.required, Validators.email]),
    company: new FormControl('', []),
    role: new FormControl('', []),
    phone: new FormControl('', [Validators.minLength(11)]),
    numberEmployees: new FormControl('', [Validators.required]),
    cityAndUf: new FormControl('', [Validators.required]),
    necessity: new FormControl('', [Validators.maxLength(300)]),
  });

  orcamentoModel: Orcamento;
  buttonLoading: boolean;
  returnError: string | undefined;

  constructor(private orcamentoService: OrcamentoService) {}

  @ViewChild(ModalOrcamentoSolicitadoComponent) childModal: ModalOrcamentoSolicitadoComponent;

  ngOnInit(): void {
    this.buttonLoading = false
  }

  get name() {
    return this.orcamentoForm.get('name');
  }

  get email() {
    return this.orcamentoForm.get('email');
  }

  get company() {
    return this.orcamentoForm.get('company');
  }

  get role() {
    return this.orcamentoForm.get('role');
  }

  get phone() {
    return this.orcamentoForm.get('phone');
  }

  get numberEmployees() {
    return this.orcamentoForm.get('numberEmployees');
  }

  get cityAndUf() {
    return this.orcamentoForm.get('cityAndUf');
  }

  get necessity() {
    return this.orcamentoForm.get('necessity');
  }

  onSubmit() {
    this.returnError = undefined;

    if (this.orcamentoForm.valid) {
      this.buttonLoading = true

      this.orcamentoModel = new Orcamento(
        this.orcamentoForm.value
      );

      this.orcamentoService.sendData(this.orcamentoModel).subscribe(
        () => {
          this.buttonLoading = false;
          this.childModal.showModal();
        },
        (error) => {
          this.buttonLoading = false;
          this.returnError = error.message || error;
        }
      );
    }
  }

  showChildModal(): void {
    this.childModal.showModal();
  }
}
