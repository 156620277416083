<footer id="footer" class="footer">
  <div class="container">
    <div class="footer-container">
      <div class="logos">
        <img src="assets/images/logo-cinco.svg" alt="Cinco" />
        <a
        href=""
        title="Google/Apple store"
        target="_blank"
        class="store-logo"
        >
          <img
            src="assets/images/google-apple-store-logo.svg"
            alt="google/apple"
          />
        </a>
        <div class="company-info-container">
          <span>CINCO</span>
          <span>CUIDADO INTELIGENTE CONECTADO LTDA</span>
          <span>CNPJ: 54.138.304/0001-49</span>
        </div>
      </div>
      <div class="logos-mobile">
        <div class="left-section-mobile">
          <img src="assets/images/logo-cinco.svg" class="cinco-mobile-icon" alt="Cinco" />
          <img
            src="assets/images/logo-hu.svg"
            class="hu-mobile-icon"
            alt="logo-hu"
          />
        </div>
        <div class="right-section-mobile">
          <div class="facebook">
            <a
              href="https://www.facebook.com/InovaHu"
              title="Fale com a Cinco pelo Facebook"
              target="_blank"
            >
              <i class="fa fa-facebook custom-icon-size" aria-hidden="true"></i>
            </a>
          </div>
          <div class="instagram">
            <a
              href="https://www.instagram.com/inovahu/"
              title="Fale com a Cinco pelo Instagram"
              target="_blank"
            >
              <i class="fa fa-instagram custom-icon-size" aria-hidden="true"></i>
            </a>

          </div>
        </div>
      </div>
      <div class="bottom-container">
        <div class="text-container">
         <span class="highlighted-info-text">O CINCO não tem a intenção ou implica a substituição de aconselhamento, diagnóstico ou tratamento médico profissional</span><br/>
         <span class="info-text">O CINCO não é um provedor de cuidados de saúde licenciado e o serviço fornecido ao usuário tem o fim apenas educativo, sendo todas</span><br/>
         <span class="info-text">as informações baseadas nos dados relatados pelo utilizador. O serviço CINCO não pode substituir o aconselhamento ou tratamento médico profissional.</span><br/>
         <span class="info-text">O diagnóstico só pode ser feito por um médico após a realização dos exames necessários.</span>
        </div>
        <div class="icon-container">
          <span>Powered by</span>
          <img
            src="assets/images/logo-hu.svg"
            alt="logo-hu"
          />
        </div>
      </div>
    </div>
  </div>
</footer>
