import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PrecoComponent } from './preco.component';
import { CtaInscricaoModule } from 'src/app/shared/cta-inscricao/cta-inscricao.module';


@NgModule({
  declarations: [
    PrecoComponent
  ],
  imports: [
    CommonModule,
    CtaInscricaoModule
  ],
  exports: [
    PrecoComponent
  ]
})
export class PrecoModule { }
