import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-parceiros',
  templateUrl: './parceiros.component.html',
  styleUrls: ['./parceiros.component.scss']
})
export class ParceirosComponent implements OnInit {
  items = [
    {
      image: 'assets/images/foz.svg',
      alt: 'foz',
    },
    {
      image: 'assets/images/upe.svg',
      alt: 'upe',
    },
    {
      image: 'assets/images/fmusp.svg',
      alt: 'fmusp',
    },
    {
      image: 'assets/images/mais-vida.svg',
      alt: 'mais-vida',
    },
  ];

  constructor() { }

  ngOnInit(): void {
  }

}
