<section class="valores" id="valores">
  <div class="container">
    <div class="row">
      <div class="coluna-1 col-sm-12 col-lg-6">
        <img
          src="assets/images/valores.png"
          class="img-fluid"
          alt="DNA da Hu.manity"
        />
      </div>
      <div class="coluna-2 col-sm-12 col-lg-6">
        <h6>Nosso DNA</h6>
        <h2>Empatia, Segurança e Ética</h2>
        <p>
          <strong
            >"Não é o diploma médico, mas a qualidade humana, o
            decisivo."</strong
          >
          <br />
          Carl Jung
        </p>
        <p>
          Ser humano é o princípio que nos move. É ter os seres humanos como
          prioridade e ser humano no cuidado com o outro. Cada pessoa é única.
          Sabendo disso, a Hu.manity é uma plataforma que promove a humanização
          da medicina, aproximando médicos e pacientes.
        </p>
      </div>
    </div>
  </div>
</section>
