import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeModule } from './pages/home/home.module';
import { ContatoModule } from './pages/contato/contato.module';
import { CommonModule } from '@angular/common';
import { PrivacyPolicyModule } from './pages/privacy-policy/privacy-policy.module';
import { TermsUseModule } from './pages/terms-use/terms-use.module';
import { FloatingButtonModule } from './shared/floating-button/floating-button.module';
import { ConsentTermModule } from './pages/consent-term/consent-term.module';
import { MateriaisModule } from './shared/materiais/materiais.module';
import { SidebarModule } from 'ng-sidebar';
import { BandHandbookModule } from './pages/band-handbook/band-handbook.module';
@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HomeModule,
    ContatoModule,
    AppRoutingModule,
    CommonModule,
    PrivacyPolicyModule,
    TermsUseModule,
    BandHandbookModule,
    FloatingButtonModule,
    ConsentTermModule,
    MateriaisModule,
    SidebarModule.forRoot(),
  ],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule {}
