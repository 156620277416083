<section class="primeiros-passos" id="primeiros-passos">
  <div class="content">
    <header>
      <h1>1</h1>
      <h2>Primeiros Passos</h2>
      <div class="subtitle">
        <span>CARREGANDO A PULSEIRA</span>
        <span>
          <b>Importante:</b> Antes de utilizar a sua pulseira de saúde, você
          precisará carregá-la via USB.
        </span>
      </div>
    </header>
    <section>
      <img
        src="../../../../assets/images/ilustracoes/etapas/abertura-pulseira.svg"
        alt="Imagem da abertura da pulseira"
      />
      <div class="instructions">
        <div class="steps-controller">
          <button
            [disabled]="instructionIndex === 0"
            (click)="goToPreviousSection()"
          >
            <img
              *ngIf="instructionIndex > 0"
              src="../../../../assets/images/icones/left-arrow.svg"
            />
            <img
              *ngIf="instructionIndex === 0"
              src="../../../../assets/images/icones/disabled-left-arrow.svg"
            />
          </button>
          <span
            ><b> {{ instructionIndex + 1 }} </b> de
            {{ instructions.length }}</span
          >
          <button
            [disabled]="instructionIndex === instructions.length - 1"
            (click)="goToNextSection()"
          >
            <img
              *ngIf="instructionIndex < instructions.length - 1"
              src="../../../../assets/images/icones/right-arrow.svg"
            />
            <img
              *ngIf="instructionIndex === instructions.length - 1"
              src="../../../../assets/images/icones/disabled-right-arrow.svg"
            />
          </button>
        </div>
        <article id="instruction-card">
          <strong>{{ instructions[instructionIndex].stepNumber }}º</strong>
          <span>
            {{ instructions[instructionIndex].description }}
          </span>
        </article>
      </div>
    </section>
  </div>
</section>
