import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AjusteComponent } from './ajuste.component';

@NgModule({
  declarations: [AjusteComponent],
  imports: [CommonModule],
  exports: [AjusteComponent],
})
export class AjusteModule {}
