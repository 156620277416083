<section class="instale" id="instale">
  <div class="content">
    <header>
      <h1>2</h1>
      <h2>Instale o aplicativo</h2>
      <span>
        <b>Importante:</b>
        Tenha a experiência CINCO e informações completas da sua pulseira de
        saúde com o aplicativo Saúde CINCO no seu smartphone. Já disponível para
        Android e iOS.
      </span>
    </header>
    <section>
      <a
        target="_blank"
        href="https://play.google.com/store/apps/details?id=com.cincoapp"
        class="store-card-button card1"
      >
        <img
          src="../../../../assets/images/icones/play-store.svg"
          alt="Ícone da Google Play"
        />
        <div class="store-card-text">
          <span>Grátis na</span>
          <img
            src="../../../../assets/images/icones/play-store-text.svg"
            alt=""
          />
        </div>
      </a>
      <a class="store-card-button card2">
        <img
          src="../../../../assets/images/icones/app-store.svg"
          alt="Ícone da App Store"
        />
        <div class="store-card-text">
          <span>Grátis na</span>
          <img
            src="../../../../assets/images/icones/app-store-text.svg"
            alt=""
          />
        </div>
      </a>
    </section>
  </div>
</section>
