<app-header></app-header>

<section id="consent-term" class="consent-term mt-5 pt-5">
    <div class="container">
        <div class="row session-head mb-3">
            <div class="col-lg-12 text-center">
                <h2 class="session-title">
                    Termo de Consentimento de Livre e Esclarecido
                </h2>
            </div>
        </div>

        <div class="row justify-content-md-center">
            <div class="col-lg-9">
                <form>
                    <div class="form-group">
                        <label for="nomepaciente">Nome do paciente </label>
                        <input type="text" readonly class="form-control" id="nomepaciente" placeholder="Nome do Paciente">
                    </div>

                    <div class="form-group">
                        <label for="nascimento-paciente">Data de nascimento </label>
                        <input type="text" readonly class="form-control" id="nascimento-paciente" placeholder="24/01/2010">
                    </div>
                </form>
            </div>

            <div class="col-lg-9">
                <div class="card">
                    <div class="card-body">
                        <h5 class="card-title">Dados do Representante Legal<br>
                            <small>Exclusivo para pacientes menores de 18 anos</small>
                        </h5>
                        

                    <form>
                        <div class="form-group">
                            <label for="nomerl">Nome completo:</label>
                            <input type="text" class="form-control" id="nomerl" placeholder="">
                        </div>

                        <div class="form-group">
                            <label for="cpfrl">CPF:</label>
                            <input type="text" class="form-control" id="cpfrl" placeholder="">
                        </div>
                    </form>
                    </div>
                </div>
            </div>
        </div>

        <div class="row justify-content-md-center">
            <div class="col-lg-9 mt-3">
                <p>
                    Eu, (nome do paciente), nascido na data (data de nascimento), inscrito no CPF sob número (número do CPF), na qualidade de paciente [ou representante legal do paciente (nome do paciente)] declaro que fui devidamente informado pelo Dr. (nome do médico), registrado no Conselho Regional de Medicina do Estado (nome do estado) sob número (número do CRM) que a utilização da telemedicina será feita na forma de exceção, decorrente do quadro de pandemia do COVID19. Tal situação deve-se ao controle epidemiológico da pandemia assim como o isolamento social recomendado pelas entidades nacionais e mundiais de saúde, o Ministério da Saúde do Brasil e a Organização Mundial da Saúde.
                </p>

                <p>Declaro que optei pela modalidade TELECONSULTA, por livre e espontânea vontade, conforme a descrição da modalidade abaixo:
 
                </p>
                    <p>TELECONSULTA, realizada entre médico e paciente, sem exame presencial, com a possibilidade de prescrição de tratamento, solicitação de exames e outros procedimentos, bem como emissão de atestado e relatório. Ficando facultado ao médico decidir sobre a pertinência de atendimento presencial em primeira consulta.
                </p>
                    <p>Estou ciente que a telemedicina apresenta limitações por não possibilitar o meu exame médico presencial, podendo ser, em alguns casos, limitados por vídeo e/ou foto.
                </p>
                    <p>Declaro estar ciente que a telemedicina é uma alternativa nesse momento de exceção e, caso eu ou o meu médico percebamos a necessidade da avaliação presencial ou em caso de interrupção da comunicação por falha técnica, a telemedicina é considerada interrompida e o paciente encaminhado ao ambulatório ou hospital, dentro da necessidade.
                </p>
                    <p>Este consentimento para uso de imagens se dá de forma gratuita, sem qualquer custo em meu benefício ou prejuízo à minha pessoa, e poderá ser revogado a meu pedido ou solicitação.
                </p>
                    <p>Estou ciente que, uma vez consentida a telemedicina, em qualquer de sua modalidade, o médico deverá elaborar um prontuário para cada paciente, contendo os dados clínicos necessários para a boa condução do caso, sendo preenchido em ordem cronológica com data, hora, assinatura e número de registro do médico no Conselho de Medicina.
                </p>
                    <p>Declaro também que fui informado que a emissão de receitas, relatórios e atestados médicos à distância é válida em meio eletrônico, durante a realização da
                    telemedicina, segundo a Portaria MS n° 467, de 20 de março de 2020.
                </p>
                    <p>Os serviços prestados nas modalidades de telemedicina serão remunerados conforme acordo prévio entre o médico e seu paciente, pessoa física ou jurídica. Fica a critério do médico a definição e cobrança de seus honorários bem como o período de retorno, referente ao ato de telemedicina, cujos valores devem ser acordados previamente à consulta. Ao assinar esse termo declaro que aceito o acordo previamente firmado entre o eu e meu médico.
                </p>
                    <p>Reconheço a excepcionalidade do momento, e me comprometo a preservar e manter a confidencialidade das imagens (foto e vídeo), dos dados, dos diálogos, orientações, prescrições e todo o conteúdo referentes à forma da telemedicina a que fui submetido, sob pena de sanções legais por exposição de dados e imagem. Da mesma forma, afirmo meu compromisso em não gravar, fotografar ou editar qualquer momento ou etapa da telemedicina empregada, assim como asseguro minha ciência que tal fato não tem o consentimento do meu médico.
                </p>
                    <p>Toda pessoa tem o seu direito de imagem, por esta razão a publicação de qualquer material sem autorização prévia da outro trata-se de crime, de acordo com o art. 5º da Constituição Federal "são invioláveis a intimidade, a vida privada, a honra e a imagem das pessoas, assegurando o direito a indenização pelo dano material ou moral decorrente de sua violação”.
                </p>
                    <p>Assim, ao concordar com esse termo declaro estar seguro (a) e ciente para a realização da telemedicina e ao mesmo tempo. Plenamente ciente e esclarecido, DECLARO estar totalmente informado de todos os fatores de risco acima mencionados, dando o meu aceite para que os procedimentos e tratamentos propostos pelo meu médico assistente sejam levados a termo, na forma por ele indicada, no intuito do restabelecimento de minha saúde.
                </p>
            </div>
        </div>
    
    </div>
    <div class="aceite">
        <div class="col-lg-12">
            <form>
                <div class="form-group form-check">
                    <input type="checkbox" class="form-check-input" id="exampleCheck1">
                    <label class="form-check-label" for="exampleCheck1">Declaro que li e aceito este Termo de Consentimento Livre e Esclarecido sobre a telemedicina.</label>
                </div>
                <a class="btn-solid-lg page-scroll" href="">Aceitar termo</a>
            </form>
        </div>
    </div>
</section>

<app-footer></app-footer>
