<app-header></app-header>

<app-apresentacao></app-apresentacao>

<app-primeiros-passos class="band-handbook"></app-primeiros-passos>

<app-instale class="band-handbook"></app-instale>

<app-vincule class="band-handbook"></app-vincule>

<app-conhecendo class="band-handbook"></app-conhecendo>

<app-ajuste class="band-handbook"></app-ajuste>

<app-perguntas></app-perguntas>

<app-contato></app-contato>

<app-footer></app-footer>
