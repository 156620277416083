import { Component, HostListener, OnInit } from '@angular/core';

@Component({
  selector: 'app-como-funciona',
  templateUrl: './como-funciona.component.html',
  styleUrls: ['./como-funciona.component.scss'],
})
export class ComoFuncionaComponent implements OnInit {

  items = [
    {
      title: '1. Protocolos personalizados por IA',
      text: 'No app da CINCO, a pessoa monitorada é acompanhada periodicamente pela assistente virtual, através de protocolos que se ajustam conforme as respostas do indivíduo e as suas informações de saúde geradas por dispositivos integrados.',
      img: 'assets/images/woman.svg'
    },
    {
      title: '2. Dispositivos parceiros do engajamento',
      text: 'Os dispositivos que acompanham a solução CINCO capturam dados de referência automaticamente. Além de ajudar a sua equipe a entender a saúde do paciente de maneira proativa, os smartwatches desempenham um papel motivador para a pessoa monitorada.',
      img: 'assets/images/cinco-watch-item.svg',
    },
    {
      title: '3. Monitoramento de glicose',
      text: 'O app da CINCO está integrado aos glicosímetros da Accu-Chek com bluetooth. O usuário só precisa vincular uma vez e sempre que ele aferir a glicose, os dados seguirão automaticamente para o app. Para quem não tem dispositivo compatível, também é possível registrar manualmente.',
      img: 'assets/images/glucose-monitor.svg',
    },
    {
      title: '4. Atendimento humano',
      text: 'Flexibilidade para escolher se a sua empresa vai oferecer o atendimento, com sua própria equipe de enfermagem, ou nossos parceiros. A CINCO disponibiliza toda a solução tecnológica para garantir um atendimento eficiente. Conectamos tecnologia e pessoas.',
      img: 'assets/images/attendant.svg',
    },
    {
      title: '5. Dados que viram ação',
      text: 'Com a CINCO, todos os dados coletados são transformados em insights poderosos que ajudam a prevenir condições crônicas, além de acompanhar proativamente as pessoas, melhorando a experiência e a saúde dos seus pacientes.',
      img: 'assets/images/screen.svg',
    },
  ];

  constructor() {}

  ngOnInit(): void {
    this.detectScreenSize();
  }

  @HostListener('window:resize', [])
  public onResize() {
    this.detectScreenSize();
  }

  private detectScreenSize() {
  }
}
