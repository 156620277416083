<div class="row mt-3">
    <div class="cta-inscricao d-flex justify-content-center">
        <div class="small-text">
            <p>
           Experimente a Hu.manity agora mesmo.<br>
           Sem custos, sem asteriscos!
            </p>
            <a class="btn-solid-lg page-scroll" title="Cadastre-se na Humanity" href="https://app.humanity.healthcare/register/">Experimente agora</a>
        </div>
    </div>
</div>
