import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';

@Component({
  selector: 'modal',
  templateUrl: './modal-orcamento-solicitado.component.html',
  styleUrls: ['./modal-orcamento-solicitado.component.scss']
})

export class ModalOrcamentoSolicitadoComponent implements OnInit {
  @ViewChild('modal', { static: false }) modal: ModalDirective;

  constructor() {}

  ngOnInit(): void {
  }


  @Input() buttonLoading: boolean;

  showModal(): void {
    this.modal.show();
  }

  hideModal(): void {
    this.modal.hide();
  }
}
