<section id="para-quem" class="para-quem">
  <div class="container">
    <div class="row">
      <div class="coluna-1 col-sm-12 col-lg-6">
        <img
          src="assets/images/ilustracoes/para-quem.svg"
          alt="Autonomia para você"
        />
        <h2>Autonomia para você assinar e cancelar quando quiser</h2>
        <p>
          Prezamos pela transparência e facilidade de acesso. Você não precisa
          ficar preso a uma assinatura.
        </p>
      </div>
      <div class="coluna-2 col-sm-12 col-lg-6">
        <h6>Para quem?</h6>
        <h5>Médicos autônomos</h5>
        <p>
          Tenha seu consultório digital por um preço acessível e com a segurança
          que você precisa.
        </p>
        <h5>Consultórios e Clínicas</h5>
        <p>
          Tenha seu consultório digital por um preço acessível e com a segurança
          que você precisa.
        </p>
        <h5>Hospitais</h5>
        <p>
          Tenha seu consultório digital por um preço acessível e com a segurança
          que você precisa.
        </p>
        <div class="botoes">
          <a class="btn-solid-lg" href="#">Assine agora</a>
          <a
            class="btn-outline-lg"
            href="https://app.humanity.healthcare/register"
            target="_blank"
            rel="noopener noreferrer"
            >Experimente grátis</a
          >
        </div>
      </div>
    </div>
  </div>
</section>
