import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HomeComponent } from './home.component';
import { ApresentacaoModule } from './apresentacao/apresentacao.module';
import { HeaderModule } from 'src/app/shared/header/header.module';
import { FooterModule } from 'src/app/shared/footer/footer.module';
import { ModalModule } from 'src/app/shared/modal/modal.module';
import { ConhecaModule } from './conheca/conheca.module';
import { CuidadoModule } from './cuidado/cuidado.module';
import { HumanizacaoModule } from './humanizacao/humanizacao.module';
import { ValoresModule } from './valores/valores.module';
import { BeneficiosModule } from './beneficios/beneficios.module';
import { ComoFuncionaModule } from './como-funciona/como-funciona.module';
import { OrcamentoModule } from './orcamento/orcamento.module';
import { ModalTermosModule } from './modal-termos/modal-termos.module';
import { IdealParaModule } from './ideal-para/ideal-para.module';
import { PlanosModule } from './planos/planos.module';
import { ParaQuemModule } from './para-quem/para-quem.module';
import { PraticidadeModule } from './praticidade/praticidade.module';
import { LegislacaoModule } from './legislacao/legislacao.module';
import { PrecoModule } from './preco/preco.module';
import { PrescricaoModule } from './prescricao/prescricao.module';
import { CanaisModule } from './canais/canais.module';
import { PerguntasModule } from './perguntas/perguntas.module';
import { ArtigosModule } from './artigos/artigos.module';
import { SitemapModule } from './sitemap/sitemap.module';
import { FloatingButtonModule } from 'src/app/shared/floating-button/floating-button.module';
import { MateriaisModule } from 'src/app/shared/materiais/materiais.module';
import { SidebarModule } from 'ng-sidebar';
import { FQAModule } from './fqa/fqa.module';
import { FormularioModule } from './formulario/formulario.module';
import { ParceirosModule } from './parceiros/parceiros.module';

@NgModule({
  declarations: [HomeComponent],
  imports: [
    CommonModule,
    HeaderModule,
    FooterModule,
    ModalModule,
    ApresentacaoModule,
    ConhecaModule,
    CuidadoModule,
    HumanizacaoModule,
    IdealParaModule,
    PlanosModule,
    ValoresModule,
    BeneficiosModule,
    ComoFuncionaModule,
    OrcamentoModule,
    ModalTermosModule,
    ParaQuemModule,
    PraticidadeModule,
    LegislacaoModule,
    PrecoModule,
    PrescricaoModule,
    ArtigosModule,
    PerguntasModule,
    CanaisModule,
    SitemapModule,
    FloatingButtonModule,
    MateriaisModule,
    FQAModule,
    FormularioModule,
    ParceirosModule,
    SidebarModule.forRoot(),
  ],
  exports: [HomeComponent],
})
export class HomeModule {}
