<section id="legislacao" class="legislacao">
  <div class="container-fluid">
    <div class="row">
      <div class="pontos col-md-12 col-lg-1">
        <img src="assets/images/ilustracoes/pontos.svg" />
      </div>
      <div class="coluna-1 col-md-12 col-lg-5">
        <div class="titulo">
          <img
            src="assets/images/ilustracoes/legislacao.svg"
            alt="Estamos atentos à Legislação"
          />
          <h4>
            Estamos atentos à Legislação: <strong>LGPD</strong> e
            <strong>CFM</strong>
          </h4>
        </div>
        <p>
          Tratamos os dados de médicos e pacientes de forma ética, com
          princípios e valores humanos e responsáveis, dentro dos padrões de
          segurança exigidos para produtos digitais, e legal, alinhada à
          <strong>LGPD</strong> e ao <strong>CFM</strong>.
        </p>
        <div *ngIf="width >= 425">
          <p>
            <strong>Segurança dos dados</strong>: Somos uma plataforma Privacy
            by Design, com respaldo jurídico desde a concepção do produto. Seus
            dados são protegidos em servidores seguros e jamais serão
            comprometidos.
          </p>
          <p>
            <strong>Ética e atenção à legislação</strong>: Estamos 100% de
            acordo com os parâmetros legais do CFM para a promoção da
            telemedicina.
          </p>
          <p>
            <strong>Transparência</strong>: Tenha acesso ao histórico de todas
            as operações realizadas na plataforma;
          </p>
        </div>
        <div *ngIf="width < 425">
          <a
            class="botao-collapse"
            data-toggle="collapse"
            href="#{{ cards[0].id }}"
            role="button"
            aria-expanded="false"
            aria-controls="collapse"
            (click)="open($event, cards[0])"
          >
            <img
              src="{{
                cards[0].collapsed
                  ? 'assets/images/icones/menos-branco.svg'
                  : 'assets/images/icones/mais-branco.svg'
              }}"
            />
            <span>{{ cards[0].text }}</span>
          </a>
          <div class="collapse" id="{{ cards[0].id }}">
            <p>
              <strong>Segurança dos dados</strong>: Somos uma plataforma Privacy
              by Design, com respaldo jurídico desde a concepção do produto.
              Seus dados são protegidos em servidores seguros e jamais serão
              comprometidos.
            </p>
            <p>
              <strong>Ética e atenção à legislação</strong>: Estamos 100% de
              acordo com os parâmetros legais do CFM para a promoção da
              telemedicina.
            </p>
            <p>
              <strong>Transparência</strong>: Tenha acesso ao histórico de todas
              as operações realizadas na plataforma;
            </p>
          </div>
        </div>
      </div>
      <div class="coluna-2 col-md-12 col-lg-5">
        <div class="titulo">
          <img
            src="assets/images/ilustracoes/prescricao.svg"
            alt="Prescrição online"
          />
          <h4>
            Prescrição online: Estamos Integrados com a <strong>MEMED</strong>
          </h4>
        </div>
        <p>
          Prescreva de maneira simples e rápida, com a melhor ferramenta de
          prescrição médica do país. Sim, estamos integrados com a Memed.
        </p>
        <div *ngIf="width >= 425">
          <a class="link" href="#" target="_blank"
            ><i class="fa fa-youtube-play" aria-hidden="true"></i>Veja como é
            simples prescrever pela Memed</a
          >
          <p class="subtitulo"><strong>Assinatura digital</strong></p>
          <p>
            É importante lembrar que as receitas digitais só são válidas
            mediante uma assinatura digital emitida por um certificado válido,
            no padrão ICP-Brasil. Se você ainda não tem uma assinatura
            eletrônica, você pode adquirir com 50% de desconto, através da
            parceria Memed & Soluti no link abaixo:
          </p>
          <a class="link" href="#" target="_blank"
            ><i class="fa fa-arrow-right" aria-hidden="true"></i>Adquirir
            certificado digital Soluti com 50% de desconto</a
          >
        </div>
        <div *ngIf="width < 425">
          <a
            class="botao-collapse"
            data-toggle="collapse"
            href="#{{ cards[1].id }}"
            role="button"
            aria-expanded="false"
            aria-controls="collapse"
            (click)="open($event, cards[1])"
          >
            <img
              src="{{
                cards[1].collapsed
                  ? 'assets/images/icones/menos-branco.svg'
                  : 'assets/images/icones/mais-branco.svg'
              }}"
            />
            <span>{{ cards[1].text }}</span>
          </a>
          <div class="collapse pt-4" id="{{ cards[1].id }}">
            <a class="link" href="#" target="_blank"
              ><i class="fa fa-youtube-play" aria-hidden="true"></i>Veja como é
              simples prescrever pela Memed</a
            >
            <p class="subtitulo"><strong>Assinatura digital</strong></p>
            <p>
              É importante lembrar que as receitas digitais só são válidas
              mediante uma assinatura digital emitida por um certificado válido,
              no padrão ICP-Brasil. Se você ainda não tem uma assinatura
              eletrônica, você pode adquirir com 50% de desconto, através da
              parceria Memed & Soluti no link abaixo:
            </p>
            <a class="link" href="#" target="_blank"
              ><i class="fa fa-arrow-right" aria-hidden="true"></i>Adquirir
              certificado digital Soluti com 50% de desconto</a
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
