<div
    class="beneficios-panel tab-pane fade show active"
    id="tab-2"
    role="tabpanel"
    aria-labelledby="tab-2"
>
    <div class="container">
        <div class="row">
            <div class="col-12">

                <div class="d-flex flex-wrap">
                    <div class="media">
                        <img src="assets\images\icones\beneficios\consultas.svg" class="mr-3" alt="como ter uma boa experiencia do paciente">
                        <div class="media-body">
                        <h5 class="mt-0">Consultas otimizadas</h5>
                        Antes da consulta você já terá acesso ao histórico do paciente, com todas as informações prévias de doenças, medicamentos e condições de saúde que ele indicar.
                        </div>
                    </div>

                    <div class="media">
                        <img src="assets\images\icones\beneficios\teleinter.svg" class="mr-3" alt="ícone de consultas otimizadas">
                        <div class="media-body">
                        <h5 class="mt-0">Teleinterconsulta médica</h5>
                        Troque experiência com outros profissionais da área, permitindo o compartilhamento e discussão de casos no modo anonimizado.
                        </div>
                    </div>

                    <div class="media">
                        <img src="assets\images\icones\beneficios\medicamentos.svg" class="mr-3" alt="ícone de consultas otimizadas">
                        <div class="media-body">
                        <h5 class="mt-0">Minimize os riscos da interrupção do tratamento</h5>
                        Acompanhamento da aderência à terapia medicamentosa e à mudança de hábitos, engajando mais os pacientes no autocuidado; 
                        </div>
                    </div>

                    <div class="media">
                        <img src="assets\images\icones\beneficios\medical.svg" class="mr-3" alt="ícone de consultas otimizadas">
                        <div class="media-body">
                        <h5 class="mt-0">Evolução do perfil comportamental</h5>
                        Com a abertura das funcionalidades para o público geral, aumentaremos a acurácia da identificação de perfis, a fim de melhor a relação entre médicos e pacientes.

                        </div>
                    </div>

                    <div class="media media-large">
                        <img src="assets\images\icones\beneficios\saude-pessoas.svg" class="mr-3" alt="ícone de consultas otimizadas">
                        <div class="media-body">
                        <h5 class="mt-0">Para pacientes: Um aplicativo de health care completo</h5>
                        <ul>
                            <li>Tenha todas as suas requisições médicas em um só lugar;
                            </li>
                            <li>Tenha consigo sempre seu Cartão de vacina digital
                            </li>
                            <li>Registre seus medicamentos, histórico de doenças, hábitos de saúde e você não precisará mais informar essas informações em todas as consultas;
                            </li>
                            <li>Registre seu humor e sintomas diariamente para receber insights;
                            </li>
                                
                        </ul>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>
