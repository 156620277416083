<section class="conhecendo" id="conhecendo">
  <div class="content">
    <header>
      <h1>4</h1>
      <h2>Conhecendo a sua pulseira</h2>
      <span
        >Informações básicas de como ligar, desligar e navegar usando a
        pulseira:</span
      >
    </header>
    <section>
      <img
        *ngIf="width > 767"
        src="../../../../assets/images/ilustracoes/etapas/pulseira-detalhes-desktop.svg"
        alt="Imagem da abertura da pulseira"
      /><img
        *ngIf="width <= 767"
        src="../../../../assets/images/ilustracoes/etapas/pulseira-detalhes-mobile.svg"
        alt="Imagem da abertura da pulseira"
      />
      <div class="instructions">
        <div class="instruction-card">
          <span>
            <b>Ligar a pulseira:</b> Pressione o botão de navegação por alguns
            segundos.
          </span>
        </div>
        <div class="instruction-card">
          <span>
            <b>Desligar a pulseira:</b>
            Você encontrará a opção de desligar nas configurações da sua
            pulseira.
          </span>
        </div>
        <div class="instruction-card">
          <span>
            <b>Navegação:</b>
            Utilize o botão abaixo do visor para navegar na sua pulseira.
          </span>
        </div>
      </div>
    </section>
  </div>
</section>
