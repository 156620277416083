import { Component, HostListener, OnInit } from '@angular/core';

@Component({
  selector: 'app-fqa',
  templateUrl: './fqa.component.html',
  styleUrls: ['./fqa.component.scss'],
})
export class FQAComponent implements OnInit {

  questions = [
    {
      title: '1. A CINCO substitui serviços médicos?',
      answer: 'Não, a CINCO é uma solução de bem-estar e prevenção que complementa o atendimento médico, oferecendo monitoramento contínuo e insights personalizados para intervenções preventivas.',
    },
    {
      title: '2. Minha equipe de enfermagem pode prestar o atendimento?',
      answer: 'Sim! Sua empresa utilizará os dispositivos e software da CINCO, e a parte do atendimento humano poderá ser realizada pela sua equipe de enfermagem.',
    },
    {
      title: '3. O que os dispositivos monitoram?',
      answer: 'Os dispositivos vestíveis da CINCO oferecem dados referenciais de parâmetros de saúde, incluindo pressão arterial, batimento cardíaco, temperatura, oxigenação, eletrocardiograma, tempo e qualidade do sono, número de passos e distância percorrida. As informações geradas por esses dispositivos não devem ser usados como informações médicas, já que não se tratam de aparelhos médicos. Além disso, a CINCO também possui integração com glicosímetros da linha Accu-chek que possuam bluetooth. O usuário poderá conectar o seu glicosímetro e, a cada aferição, as informações sobre glicose também será um dado monitorado',
    },
  ];

  constructor() {}

  ngOnInit(): void {
    this.detectScreenSize();
  }

  @HostListener('window:resize', [])
  public onResize() {
    this.detectScreenSize();
  }

  private detectScreenSize() {
  }
}
