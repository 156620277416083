<section id="canais" class="canais">
  <div class="container">
    <div class="textos text-center">
      <h2>Entre em contato</h2>
      <p>
        Envie-nos um email ou fale conosco por qualquer um de nossos canais:
      </p>
      <br />
      <img src="assets/images/ilustracoes/contato.svg" alt="Entre em contato" />
      <a href="mailto:atendimento@saudecinco.com">
        <p><strong>atendimento@saudecinco.com
        </strong></p>
      </a>
    </div>
    <div class="icones">
      <div class="whatsapp">
        <a
          href="https://www.instagram.com/inovahu/"
          title="Fale com a Cinco pelo Instagram"
          target="_blank"
        >
          <i class="fa fa-instagram" aria-hidden="true"></i>
        </a>

      </div>

      <div class="facebook">
        <a
          href="https://www.facebook.com/InovaHu"
          title="Fale com a Cinco pelo Facebook"
          target="_blank"
        >
          <i class="fa fa-facebook" aria-hidden="true"></i>
        </a>
      </div>
      <div class="linkedin">
        <a
          href="https://www.linkedin.com/company/inovahu/"
          title="Fale com a Cinco pelo Facebook"
          target="_blank"
        >
          <i class="fa fa-linkedin" aria-hidden="true"></i>
        </a>
      </div>
    </div>
  </div>
</section>
