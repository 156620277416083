import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FQAComponent } from './fqa.component';

@NgModule({
  declarations: [FQAComponent],
  imports: [CommonModule],
  exports: [FQAComponent],
})
export class FQAModule {}
