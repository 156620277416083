<div
    class="beneficios-panel tab-pane fade show active"
    id="tab-1"
    role="tabpanel"
    aria-labelledby="tab-1"
>
    <div class="container">
        <div class="row">
            <div class="col-12">
                <div class="d-flex flex-wrap">
                    

                    <div class="media">
                        <img src="assets\images\icones\beneficios\usabilidade.svg" class="mr-3" alt="plataforma fácil de usar">
                        <div class="media-body">
                        <h5 class="mt-0">Facilidade de uso</h5>
                        A Hu.manity é uma plataforma intuitiva e você não precisa de um tutorial para usá-la
                        </div>
                    </div>

                    <div class="media">
                        <img src="assets\images\icones\beneficios\empatia.svg" class="mr-3" alt="como ter mais empatia no atendimento médico">
                        <div class="media-body">
                        <h5 class="mt-0">Compromisso com a empatia</h5>
                        Receba insights sobre o perfil comportamental daquele cliente, que possa te ajudar a ter uma consulta mais satisfatória.
                        </div>
                    </div>

                    <div class="media">
                        <img src="assets\images\icones\beneficios\nuvem.svg" class="mr-3" alt="o que são prontuários na nuvem">
                        <div class="media-body">
                        <h5 class="mt-0">Prontuário em nuvem</h5>
                        Acesse a sua agenda, faça modificações, atenda e consulte prontuários a qualquer hora e de onde você estiver, pelo seu smartphone ou desktop.
                        </div>
                    </div>

                    <div class="media">
                        <img src="assets\images\icones\beneficios\backup.svg" class="mr-3" alt="como realizar backup de prontuários médicos">
                        <div class="media-body">
                        <h5 class="mt-0">Backup de prontuários</h5>
                        As informações sobre os seus atendimentos são sigilosas e suas. Não se preocupe, você sempre pode recuperá-las quando quiser.
                        </div>
                    </div>

                    <div class="media">
                        <img src="assets\images\icones\beneficios\automacao.svg" class="mr-3" alt="como melhorar os processos de telemedicina">
                        <div class="media-body">
                        <h5 class="mt-0">Automatização de processos</h5>
                        Além dos processos de agendamento, lembretes, consulta e pós-consulta, nós cuidamos do aceite do Termo de Consentimento Livre Esclarecido pelo seu paciente e do Relatório da consulta médica.

                        </div>
                    </div>

                    <div class="media">
                        <img src="assets\images\icones\beneficios\prescricao.svg" class="mr-3" alt="como prescrever digitalmente">
                        <div class="media-body">
                        <h5 class="mt-0">Prescrição digital</h5>
                        Você poderá utilizar a melhor plataforma de prescrição de medicamentos e exames do Brasil. Você só precisará ter uma assinatura digital válida. 
                        </div>
                    </div>

                    <div class="media">
                        <img src="assets\images\icones\beneficios\pacientes.svg" class="mr-3" alt="como aumentar a aquisição de pacientes">
                        <div class="media-body">
                        <h5 class="mt-0">Maior aquisição e engajamento de pacientes</h5>
                        Ofereça mais autonomia para o seu paciente realizar o auto agendamento, compartilhando o seu perfil com a rede. Após o atendimento, indique períodos para retorno e nós enviaremos lembretes, junto com as orientações que ele precisa.
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</div>