import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ArtigosComponent } from './artigos.component';



@NgModule({
  declarations: [
    ArtigosComponent
  ],
  imports: [
    CommonModule
  ],
  exports: [
    ArtigosComponent
  ]
})
export class ArtigosModule { }
