<section id="materiais" class="materiais">
  <div class="container-fluid">
    <div class="container">
      <div class="row">
        <div class="coluna-1 col-sm-12 col-lg-6">
          <h5>Material Gratuito</h5>
          <h3>Guia da Experiência do Paciente</h3>
          <p>
            Resultado de uma pesquisa com pacientes de todos o Brasil, em Abril
            de 2020, o Guia da Experiência do Paciente possui dados valiosos
            sobre aspectos favoráveis e prejudiciais aos pacientes durante as
            consultas médicas.
          </p>
          <p>
            Entenda, através da visão dos próprios pacientes, como a experiência
            em consultas físicas e virtuais podem ser mais satisfatórias.
          </p>
          <p>O material é gratuito e está disponível no link abaixo.</p>
          <a class="btn-solid-lg" href="#">Baixe grátis</a>
        </div>
        <div class="coluna-2 col-sm-12 col-lg-6">
          <img
            src="assets/images/mockups/mockup-material.png"
            class="img-fluid"
            alt="Experiência do paciente"
          />
        </div>
      </div>
    </div>
  </div>
</section>
