import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BeneficiosComponent } from './beneficios.component';
import { AtuaisComponent } from './atuais/atuais.component';
import { FuturosComponent } from './futuros/futuros.component';
import { CtaInscricaoModule } from 'src/app/shared/cta-inscricao/cta-inscricao.module';

@NgModule({
  declarations: [
    BeneficiosComponent,
    AtuaisComponent,
    FuturosComponent
  ],
  imports: [
    CommonModule,
    CtaInscricaoModule
  ],
  exports: [
    BeneficiosComponent
  ]
})
export class BeneficiosModule { }
