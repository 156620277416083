import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-perguntas',
  templateUrl: './perguntas.component.html',
  styleUrls: ['./perguntas.component.scss'],
})
export class PerguntasComponent implements OnInit {
  activeQuestionIndex: undefined | 1 | 2 | 3 | 4 | 5 = undefined;
  animationState = 'in';

  constructor() {}

  ngOnInit(): void {}

  changeQuestionDisplayStatus(questionIndex: 1 | 2 | 3 | 4 | 5) {
    if (
      questionIndex === this.activeQuestionIndex &&
      this.activeQuestionIndex
    ) {
      this.activeQuestionIndex = undefined;
    } else {
      this.activeQuestionIndex = questionIndex;
    }
  }
}
