import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ConhecendoComponent } from './conhecendo.component';

@NgModule({
  declarations: [ConhecendoComponent],
  imports: [CommonModule],
  exports: [ConhecendoComponent],
})
export class ConhecendoModule {}
