import { Component, HostListener, OnInit, Input } from '@angular/core';
import * as $ from 'jquery';
import 'magnific-popup';
import 'jquery.easing';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  links = [
    {
      href: '#conheca',
      texto: 'Conheça',
    },
    {
      href: '#cuidado',
      texto: 'Benefícios',
    },
    {
      href: '#como-funciona',
      texto: 'Como funciona',
    },
    // {
    //   href: '#planos',
    //   texto: 'Planos',
    // },
    {
      href: '#canais',
      texto: 'Contato',
    },
  ];
  opened = false;

  constructor() {}

  @Input() type: string;

  ngOnInit(): void {
    (function ($) {
      'use strict';
      // jQuery for page scrolling feature - requires jQuery Easing plugin
      $(function () {
        $(document).on('click', 'a.page-scroll', function (event) {
          const $anchor = $(this);

          $('html, body')
            .stop()
            .animate(
              {
                scrollTop: $($anchor.attr('href')).offset().top - 150,
              },
              500,
              'easeInOutExpo'
            );
          event.preventDefault();
        });
      });
    })($);
  }

  @HostListener('window:scroll', ['$event'])
  onWindowScroll(event) {
    let element = document.querySelector('.container-fluid');

    if (window.pageYOffset > element.clientHeight) {
      element.classList.add('scroll');

      $('#logo-header').attr('src', 'assets/images/logo-cinco-2.svg');
    } else {
      element.classList.remove('scroll');

      $('#logo-header').attr('src', 'assets/images/logo-cinco.svg');
    }
  }

  toggleSidebar() {
    this.opened = !this.opened;
    let element = document.querySelector('ng-sidebar-container');

    if (this.opened) {
      element.classList.add('sidebar');
    } else {
      element.classList.remove('sidebar');
    }
  }
}
