import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ConhecaComponent } from './conheca.component';



@NgModule({
  declarations: [
    ConhecaComponent
  ],
  imports: [
    CommonModule
  ],
  exports: [
    ConhecaComponent
  ]
})
export class ConhecaModule { }
