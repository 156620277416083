import { Component, HostListener, OnInit } from '@angular/core';

@Component({
  selector: 'app-ideal-para',
  templateUrl: './ideal-para.component.html',
  styleUrls: ['./ideal-para.component.scss'],
})
export class IdealParaComponent implements OnInit {
  itens = [
    {
      title: 'Operadoras de saúde',
      text:
        'Para diminuir sinistros da população com doenças crônicas',
      image: 'assets/images/ideal-para-1.png',
    },
    {
      title: 'Lares de idosos',
      text:
        'Para quem pretende oferecer diferenciação no cuidado especial',
      image: 'assets/images/ideal-para-2.png',
    },
    {
      title: 'Programas de Prevenção',
      text:
        'Para ter efetividade nos resultados, com mais engajamento',
      image: 'assets/images/ideal-para-3.png',
    },
    {
      title: 'Clínicas e Home cares',
      text:
        'Para acompanhar pacientes e desfechos clínicos remotamente',
      image: 'assets/images/ideal-para-4.png',
    },
  ];
  height: number;
  width: number;

  constructor() {}

  ngOnInit(): void {
    this.detectScreenSize();
  }

  @HostListener('window:resize', [])
  public onResize() {
    this.detectScreenSize();
  }

  private detectScreenSize() {
    this.height = window.innerHeight;
    this.width = window.innerWidth;
  }
}
