import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Orcamento } from './DTOs/Orcamento';

@Injectable()
export class OrcamentoService {
  private URL: string = environment.URL;

  constructor(private http: HttpClient) {}

  sendData(data: Orcamento): Observable<Orcamento> {
    return this.http
      .post<Orcamento>(`${this.URL}/externalContact`, data)
      .pipe(catchError(this.handleError));
  }

  private handleError(data: HttpErrorResponse) {
    const messageError = data.error.message || 'Ops... Tivemos um problema, tente novamente mais tarde.';

    return throwError(messageError);
  }
}
