<section class="orcamento" id="orcamento">
  <div class="container">
    <div class="row">
      <div class="coluna-1 col-lg-12 col-xl-3">
        <img
          src="assets/images/ilustracoes/orcamento.svg"
          class="img-fluid"
          alt="Icone"
        />

        <h2>Solicite um orçamento</h2>

        <strong>Receba por e-mail ou Whatsapp</strong>
      </div>

      <div class="coluna-2 col-lg-12 col-xl-9">
        <form
          id="orcamentoForm"
          [formGroup]="orcamentoForm"
          #myForm="ngForm"
          (ngSubmit)="onSubmit()"
        >
          <div class="row">
            <div class="col-lg-7">
              <div class="form-group">
                <label>Nome:</label>

                <input
                  type="text"
                  class="{{ name.invalid && myForm.submitted ? 'error' : '' }}"
                  formControlName="name"
                  required
                />

                <span
                  *ngIf="name.errors?.required && myForm.submitted"
                >
                  O campo nome é obrigatório.
                </span>
              </div>

              <div class="form-group">
                <label>Email:</label>

                <input
                  type="email"
                  class="{{ email.invalid && myForm.submitted ? 'error' : '' }}"
                  formControlName="email"
                  required
                />

                <span
                  *ngIf="email.errors?.required && myForm.submitted"
                >
                  O campo e-mail é obrigatório.
                </span>

                <span
                  *ngIf="!email.errors?.required && email.errors?.email && myForm.submitted"
                >
                  Informe um e-mail válido.
                </span>
              </div>

              <div class="form-row-alternative">
                <div class="form-group">
                  <label>Empresa (opcional):</label>

                  <input
                    type="text"
                    formControlName="company"
                  />
                </div>

                <div class="form-group">
                  <label>Cargo</label>

                  <input
                    type="text"
                    formControlName="role"
                  />
                </div>
              </div>

              <div class="form-row-alternative">
                <div class="form-group">
                  <label>Whatsapp/Telefone:</label>

                  <input
                    type="text"
                    class="{{ phone.invalid && myForm.submitted ? 'error' : '' }}"
                    formControlName="phone"
                    mask='(00) 00000-0000'
                    required
                  />

                  <span
                    *ngIf="phone.errors?.required && myForm.submitted"
                  >
                    O número de whatsapp/telefone é obrigatório.
                  </span>

                  <span
                    *ngIf="!phone.errors?.required && phone.errors?.minlength && myForm.submitted"
                  >
                    Informe um whatsapp/telefone válido.
                  </span>
                </div>

                <div class="form-group">
                  <label>Quantidade de assinaturas:</label>

                  <input
                    type="number"
                    class="{{ numberEmployees.invalid && myForm.submitted ? 'error' : '' }}"
                    formControlName="numberEmployees"
                    required
                  />

                  <span
                    *ngIf="numberEmployees.errors?.required && myForm.submitted"
                  >
                    A quantidade de assinaturas é obrigatória.
                  </span>
                </div>
              </div>
            </div>

            <div class="col">
              <div class="form-group">
                <label>Cidade/Estado:</label>

                <input
                  type="text"
                  class="{{ cityAndUf.invalid && myForm.submitted ? 'error' : '' }}"
                  formControlName="cityAndUf"
                  required
                />

                <span
                  *ngIf="cityAndUf.errors?.required && myForm.submitted"
                >
                  A cidade/estado são obrigatórios.
                </span>
              </div>

              <div class="form-group-textarea">
                <label>Descreva a sua necessidade (opcional):</label>

                <textarea
                  class="{{ necessity.invalid && myForm.submitted ? 'error' : '' }}"
                  formControlName="necessity"
                > </textarea>

                <span
                  *ngIf="necessity.invalid && myForm.submitted"
                >
                  Quantidade de caracteres excedido (máx 300).
                </span>
              </div>
            </div>
          </div>

          <div class="row-observations">
            <div>
              <img
                src="assets/images/icones/olho.svg"
                class="img-fluid"
                alt="Icone olho"
              />

              <p>
                Ao solicitar um orçamento, você concorda com os
              </p>
            </div>

            <a
              href="/termos-e-privacidade"
              target="_blank"
            >
              Termos e Política de privacidade
            </a>
          </div>

          <div class="row align-buttons">
            <button class="btn-solid-lg btn-solid-lg-white"
              [disabled]="buttonLoading"
            >
              <span *ngIf="!buttonLoading">
                Solicitar agora
              </span>
              <i class="fa fa-spinner" aria-hidden="true" *ngIf="buttonLoading"></i>
            </button>

            <div class="alert-error" *ngIf="returnError">
              <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>

              <span>
                {{ returnError }}
              </span>
            </div>
          </div>

        </form>
      </div>
    </div>
  </div>

  <!-- <button type="button" class="btn btn-primary" (click)="showChildModal()">Open child modal</button> -->

  <modal [buttonLoading]="buttonLoading">
  </modal>
</section>
