<section id="artigos" class="artigos">
    <div class="container">
        <div class="row session-head">
            <div class="col-lg-12 text-center">
                <h6 class="session-subtitle">Artigos sobre saúde</h6>
                <h2 class="session-title">
                    Conteúdo online
                </h2>
            </div>
        </div>

        <div class="row justify-content-md-center">

            <div class="col-lg-4">
            <div class="card card-artigos">
                <div class="card-top">
                    <img src="https://blog.inovahu.com/wp-content/uploads/2020/05/transfor3-930x620.jpg" class="card-img-top">
                </div>
                
                <div class="card-body">
                    <div class="row">
                        <div class="col-auto">
                            <article>
                                <h5>Experiência do paciente na teleorientação médica</h5>
                                <p>Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. </p>
                                <a href="#">Continue lendo</a>
                            </article>
                        </div>
                    </div>
                </div>
            </div>
            </div>

            <div class="col-lg-4">
            <div class="card card-artigos">
                <div class="card-top">
                    <img src="https://blog.inovahu.com/wp-content/uploads/2020/05/transfor3-930x620.jpg" class="card-img-top">
                </div>
                
                <div class="card-body">
                    <div class="row">
                        <div class="col-auto">
                            <article>
                                <h5>Experiência do paciente na teleorientação médica</h5>
                                <p>Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. </p>
                                <a href="#">Continue lendo</a>
                            </article>
                        </div>
                    </div>
                </div>
            </div>
            </div>

            <div class="col-lg-4">
            <div class="card card-artigos">
                <div class="card-top">
                    <img src="https://blog.inovahu.com/wp-content/uploads/2020/05/transfor3-930x620.jpg" class="card-img-top">
                </div>
                
                <div class="card-body">
                    <div class="row">
                        <div class="col-auto">
                            <article>
                                <h5>Experiência do paciente na teleorientação médica</h5>
                                <p>Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. </p>
                                <a href="#">Continue lendo</a>
                            </article>
                        </div>
                    </div>
                </div>
            </div>
            </div>

        </div>

        <div class="col-12 mt-3">
            <div class="d-flex justify-content-center">
                <a href="https://medium.com/humanity-health" title="Ir para o blog da Hu.manity no Medium" target="_blank"
                class="btn btn-humanity-outline">Veja todos os artigos</a>
            </div>
        </div>

        
    </div>
</section>
