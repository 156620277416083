<section id="ideal-para" class="ideal-para">
  <div class="container text-center">
    <h2>Ideal para quem?</h2>

    <p>A CINCO é ideal para qualquer empresa que queira revolucionar o engajamento de pacientes e melhorar a experiência de saúde, incluindo:</p>

    <div class="row" *ngIf="width >= 992">
      <div
        class="item col-md-12 col-lg-3"
        *ngFor="let item of itens; let i = index"
      >
        <img src="{{ item.image }}" class="img-fluid" />

        <h5>{{ item.title }}</h5>

        <p>{{ item.text }}</p>
      </div>
    </div>

    <div class="row" *ngIf="width < 992">
      <div class="col">
        <div id="carouselIndicatorIdealPara" class="carousel slide" data-ride="carousel">
          <ol class="carousel-indicators">
            <li
              data-target="#carouselIndicatorIdealPara"
              data-slide-to="0"
              class="active"
            ></li>
            <li data-target="#carouselIndicatorIdealPara" data-slide-to="1"></li>
            <li data-target="#carouselIndicatorIdealPara" data-slide-to="2"></li>
            <li data-target="#carouselIndicatorIdealPara" data-slide-to="3"></li>
          </ol>
          <div class="carousel-inner">
            <div
              class="carousel-item"
              *ngFor="let item of itens; let isFirst = first"
              [ngClass]="{ active: isFirst }"
            >
              <img src="{{ item.image }}" class="img-fluid" />

              <h5>{{ item.title }}</h5>

              <p>{{ item.text }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
