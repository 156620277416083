<section id="como-funciona" class="como-funciona">
  <div class="container">
    <div class="grid-container">
      <div class="left-div">

        <h2>Como
        funciona</h2>

        <p>
          Entenda como a CINCO coloca o usuário no centro do cuidado, entregando uma solução totalmente personalizada, integrada e fácil de usar.
          <br/><br/>
          O engajamento do usuário é o fator primordial para garantir bons resultados em qualquer ação de saúde preventiva.
        </p>

      </div>
      <div class="right-div">

        <div class="features-container">
          <div class="feature-item" *ngFor="let item of items; let i = index">
            <img
            src="{{ item.img }}"
            alt=""
            />

            <div class="feature-text-container">
              <h2>{{ item.title }}</h2>
              <p>
                {{ item.text }}
              </p>
            </div>

          </div>

        </div>

      </div>
    </div>
      <div id="carouselIndicatorComoFunciona" class="carousel slide" data-ride="carousel">
        <ol class="carousel-indicators">
          <li
            data-target="#carouselIndicatorComoFunciona"
            data-slide-to="0"
            class="active"
          ></li>
          <li data-target="#carouselIndicatorComoFunciona" data-slide-to="1"></li>
          <li data-target="#carouselIndicatorComoFunciona" data-slide-to="2"></li>
          <li data-target="#carouselIndicatorComoFunciona" data-slide-to="3"></li>
          <li data-target="#carouselIndicatorComoFunciona" data-slide-to="4"></li>
        </ol>
        <div class="carousel-inner">
          <div
            class="carousel-item"
            *ngFor="let item of items; let isFirst = first"
            [ngClass]="{ active: isFirst }"
          >

              <div class="carousel-text-container">
                <h2>Como funciona</h2>

                <p>
                  Entenda como a CINCO coloca o usuário no centro do cuidado, entregando uma solução totalmente personalizada, integrada e fácil de usar.
                  <br/><br/>
                  O engajamento do usuário é o fator primordial para garantir bons resultados em qualquer ação de saúde preventiva.
                </p>
              </div>

              <img
                src="{{ item.img }}"
                alt=""
              />

              <div class="feature-text-container">
                <h2>{{ item.title }}</h2>
                <p>
                  {{ item.text }}
                </p>
              </div>

          </div>
        </div>
      </div>

  </div>

</section>
