import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BandHandbookComponent } from './band-handbook.component';
import { HeaderModule } from 'src/app/shared/header/header.module';
import { FooterModule } from 'src/app/shared/footer/footer.module';
import { ApresentacaoModule } from './apresentacao/apresentacao.module';
import { PrimeirosPassosModule } from './primeiros-passos/primeiros-passos.module';
import { InstaleModule } from './instale/instale.module';
import { VinculeModule } from './vincule/vincule.module';
import { ConhecendoModule } from './conhecendo/conhecendo.module';
import { AjusteModule } from './ajuste/ajuste.module';
import { PerguntasModule } from './perguntas/perguntas.module';
import { ContatoModule } from './contato/contato.module';

@NgModule({
  declarations: [BandHandbookComponent],
  imports: [
    CommonModule,
    HeaderModule,
    ApresentacaoModule,
    PrimeirosPassosModule,
    InstaleModule,
    VinculeModule,
    ConhecendoModule,
    AjusteModule,
    PerguntasModule,
    ContatoModule,
    FooterModule,
  ],
  exports: [BandHandbookComponent],
})
export class BandHandbookModule {}
