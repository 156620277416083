import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InstaleComponent } from './instale.component';

@NgModule({
  declarations: [InstaleComponent],
  imports: [CommonModule],
  exports: [InstaleComponent],
})
export class InstaleModule {}
