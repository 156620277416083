<section id="praticidade" class="praticidade mt-5">
    <div class="container">
        <div class="row session-head">
            <div class="col-lg-12 text-center">
                <h6 class="session-subtitle">SIMPLIFICAMOS A BUROCRACIA</h6>
                <h2 class="session-title">
                    Médicos cuidando das pessoas.<br>A tecnologia, dos processos
                </h2>
            </div>
        </div>

        <div class="row texto-intro">
                <p>Nós cuidamos da automação de processos necessários e cuidamos de todas as obrigações legais e de segurança para que os médicos possam se dedicar ao que mais importa: as pessoas. Veja como é simples usar a Hu.manity:
                </p>
        </div>

        <div class="d-flex">

            <ul class="slider">

                 <li>
                    <input type="radio" id="slide1" name="slide" checked>
                    <label for="slide1"></label>
                    <div class="card">
                        <div class="card-body">
                            <div class="media">
                                <div class="media-body">
                                  <div class="etapa">
                                      <h5>1</h5>
                                  </div>
                                  <h5 class="mt-2 mb-1">Cadastre-se</h5>
                                  Informe dados de CRM e Rqe, sua biografia ou texto sobre sua área de atuação e as formas de pagamento. O nosso cadastro é tão simples, que você levará no máximo 2 minutos para concluir.

                                  <div class="navegation-mobile">
                                    Toque para ver o próximo <img src="assets\images\icones\gestures_down.svg">
                                  </div>
                                </div>
                                <img class="img-fluid" src="assets\images\mockups\cadastro.svg" />
                            </div>
                        </div>
                    </div>
                 </li>
                 <li>
                    <input type="radio" id="slide2" name="slide">
                    <label for="slide2"></label>
                    <div class="card">
                        <div class="card-body">
                            <div class="media">
                                <div class="media-body">
                                  <div class="etapa">
                                      <h5>2</h5>
                                  </div>
                                  <h5 class="mt-2 mb-1">Disponibilize sua agenda</h5>
                                  Configure os dias, horários e duração de consulta para que os pacientes possam realizar o auto agendamento. Você pode acessar e alterar a qualquer momento.

                                  <div class="navegation-mobile">
                                    Toque para ver o próximo <img src="assets\images\icones\gestures_down.svg">
                                  </div>
                                </div>
                                <img class="img-fluid" src="assets\images\mockups\agenda.svg" />
                            </div>
                        </div>
                    </div>
                 </li>

                 <li>
                    <input type="radio" id="slide3" name="slide">
                    <label for="slide3"></label>
                    <div class="card">
                        <div class="card-body">
                            <div class="media">
                                <div class="media-body">
                                  <div class="etapa">
                                      <h5>3</h5>
                                  </div>
                                  <h5 class="mt-2 mb-1">Compartilhe seu perfil e link de agendamento</h5>
                                  Simplifique a jornada do seu cliente para chegar até você e tenha controle simples e automático de sua agenda. 

                                  <div class="navegation-mobile">
                                    Toque para ver o próximo <img src="assets\images\icones\gestures_down.svg">
                                  </div>
                                </div>
                                <img class="img-fluid" src="assets\images\mockups\smartphone-perfil-medico.svg" />
                            </div>
                        </div>
                    </div>
                 </li>

                 <li>
                    <input type="radio" id="slide4" name="slide" >
                    <label for="slide4"></label>
                    <div class="card">
                        <div class="card-body">
                            <div class="media">
                                <div class="media-body">
                                  <div class="etapa">
                                      <h5>4</h5>
                                  </div>
                                  <h5 class="mt-2 mb-1">Confirme um agendamento</h5>
                                  Quando um paciente agendar uma consulta, você será notificado. Você poderá confirmar a consulta e receberá acesso para falar com o paciente, caso seja necessário.  

                                </div>
                                <img class="img-fluid" src="assets\images\mockups\confirmacao-consulta.jpg" />
                            </div>
                        </div>
                    </div>
                 </li>

             </ul>

        </div>

        <div class="clearfix cta-mobile">
            <app-cta-inscricao></app-cta-inscricao>
        </div>
        

    </div>
</section>