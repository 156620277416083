<section id="contato" class="contato">
  <div class="container">
    <div class="textos text-center">
      <h2>Canais de Atendimento</h2>
      <p>Fale conosco por um de nossos canais de atendimento:</p>
      <br />
      <img src="assets/images/ilustracoes/contato.svg" alt="Entre em contato" />
      <a href="mailto:atendimento@saudecinco.com
      ">
        <p><strong>atendimento@saudecinco.com
        </strong></p>
      </a>
    </div>
  </div>
</section>
