<section id="apresentacao" class="apresentacao">
  <div class="container-fluid text-center">
    <div class="container">
      <div class="row">
        <h1>Manual do usuário CINCO</h1>
        <h2>Tudo o que você precisa saber sobre a sua Pulseira de Saúde</h2>
      </div>
      <div class="row">
        <p>Etapas</p>
        <div class="steps">
          <button
            class="section-scroll"
            (click)="scrollToSection('#primeiros-passos')"
          >
            1
          </button>
          <div></div>
          <button class="section-scroll" (click)="scrollToSection('#instale')">
            2
          </button>
          <div></div>
          <button class="section-scroll" (click)="scrollToSection('#vincule')">
            3
          </button>
          <div class="vincule-row"></div>
          <button
            class="section-scroll"
            (click)="scrollToSection('#conhecendo')"
          >
            4
          </button>
          <div></div>
          <button class="section-scroll" (click)="scrollToSection('#ajuste')">
            5
          </button>
        </div>
      </div>
    </div>
  </div>
</section>
