import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-prescricao',
  templateUrl: './prescricao.component.html',
  styleUrls: ['./prescricao.component.scss']
})
export class PrescricaoComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
