<section id="formulario" class="formulario">
  <div class="container">
   <div class="col">
    <div class="form-section-container">
        <div class="form-info-container">
          <img
            src="assets/images/material.svg"
            alt="Icone material"
          />
          <h1>Receba o</h1>
          <h1> nosso material</h1>
          <span>Receba por e-mail ou Whatsapp</span>
        </div>
        <form [formGroup]="formulario" (ngSubmit)="onSubmit()" class="form-section">
          <div class="form-container">
            <div class="form-item">
             <label for="nome">Nome:</label>
             <input id="nome" formControlName="nome" type="text">
            </div>
            <div class="form-item">
             <label for="email">E-mail:</label>
             <input id="email" formControlName="email" type="email">
            </div>
            <div class="form-horizontal-items">
             <div class="form-item">
               <label for="whatsapp">Whatsapp/Telefone:</label>
               <input id="whatsapp" formControlName="whatsapp" type="text">
             </div>
             <div class="form-item">
               <label for="empresa">Empresa:</label>
               <input id="empresa" formControlName="empresa" type="text">
             </div>
            </div>
           </div>

          <re-captcha class="re-captcha" (resolved)="handleCaptchaResponse($event)"
              name="recaptchaReactive" siteKey="{{recaptchaSiteKey}}">
          </re-captcha>

           <div class="info-container">
              <img
                src="assets/images/icones/olho.svg"
                class="img-fluid"
                alt="Icone olho"
              />
              <div>
                <span class="mobile-text">
                  Ao solicitar um orçamento, você<br/> concorda com os <a href="">Termos e Política<br/> de Privacidade</a>
                </span>
                <span class="desktop-text">
                  Ao solicitar um orçamento, você concorda com os <a href="">Termos e Política<br/> de Privacidade</a>
                </span>
              </div>
           </div>

           <button type="submit">Solicitar agora</button>
          </form>
    </div>
   </div>
  </div>

</section>
