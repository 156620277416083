import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './header.component';
import { SidebarModule } from 'ng-sidebar';

@NgModule({
  declarations: [HeaderComponent],
  imports: [CommonModule, SidebarModule.forRoot()],
  exports: [HeaderComponent],
})
export class HeaderModule {}
