<div bsModal #modal="bs-modal" class="modal fade" tabindex="-1"
     role="dialog" aria-labelledby="dialog-child-name">
  <div class="modal-content">
    <div class="botao-fechar">
      <button class="close" aria-label="Close" (click)="hideModal()">
        <img src="assets/images/icones/botao-fechar.svg" />
      </button>
    </div>

    <div class="ilustracao">
      <img src="assets/images/ilustracoes/orcamento-2.svg" />
    </div>

    <div class="texto">
      <h1>Orçamento solicitado</h1>

      <p>
        Agradecemos a sua solicitação.
        Você deverá receber o nosso orçamento nos próximos dias.
      </p>

      <P>
        Por precaução, confira também a sua caixa de spam nos próximos dias.
      </P>

      <button
        type="button"
        class="btn-outline-lg btn-outline-lg-secondary"
        aria-label="Close" (click)="hideModal()"
      >
        Entendi
      </button>
    </div>
  </div>
</div>
