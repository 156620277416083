<section id="apresentacao" class="apresentacao">
  <div class="container-fluid">
    <div class="container">
        <div class="col">
            <span>Cuidado inteligente e conectado</span>
            <h1>A nova era do cuidado preventivo: Monitoramento Inteligente de saúde</h1>
            <p>
              Acompanhe a saúde de seus pacientes de forma personalizada e proativa, promovendo maior adesão ao tratamento e prevenindo complicações.<br/><br/>
              Não somos apenas uma solução tecnológica.
              A CINCO é o futuro da saúde conectada.
            </p>

          <div class="botoes">
            <a class="btn-outline-lg btn-outline-lg-white page-scroll" href="#conheca">Saiba mais</a>
          </div>
        </div>
        <div class="col-mobile">
          <span>Cuidado inteligente e conectado</span>
          <h1>A nova era do cuidado<br/> preventivo: Monitoramento Inteligente de<br/> saúde</h1>
          <p>
            Acompanhe a saúde de seus<br/> pacientes de forma personalizada e proativa, promovendo maior adesão<br/> ao tratamento e prevenindo complicações.<br/>
            Não somos apenas uma solução tecnológica.<br/>
            A CINCO é o futuro da saúde<br/> conectada.
          </p>
          <div class="botoes">
            <a class="btn-outline-lg btn-outline-lg-white page-scroll" href="#conheca">Saiba mais</a>
          </div>
        </div>
        <div class="svg-container">
          <img
            src="assets/images/cellphone.svg"
            class="cellphone"
            alt="celular"
          />
          <img
            src="assets/images/bracelet-flowchart.svg"
            class="cellphone"
            alt="celular"
          />
        </div>
    </div>
  </div>
</section>
