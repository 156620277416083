import { Component, HostListener, OnInit } from '@angular/core';

@Component({
  selector: 'app-perguntas',
  templateUrl: './perguntas.component.html',
  styleUrls: ['./perguntas.component.scss'],
})
export class PerguntasComponent implements OnInit {
  perguntas = [
    {
      id: 'collapse01',
      title: 'Quais são os pré-requisitos para usar a plataforma Hu.manity?',
      content:
        'Para usar a plataforma é preciso ter um registro no CRM e, quando for o caso, o RQE. ' +
        'Além disso, também será de grande importância que você, ou o seu consultório, possua um ' +
        'canal de comunicação rápida, como o Whatsapp ou Telegram. Não é pré-requisito, mas para ' +
        'utilizar qualquer solução de Telemedicina também será importante possuir um certificado ' +
        'digital, a fim de viabilizar prescrições eletrônicas.',
      collapsed: false,
    },
    {
      id: 'collapse02',
      title: 'Como eu recebo o valor pago pelas consultas?',
      content:
        'Você, médico(a), pode cadastrar as formas de pagamento que deseja receber e o paciente as ' +
        'visualizará durante o agendamento. Por enquanto, você poderá cadastrar TED Bancários, PayPal, ' +
        'PagSeguro e PicPay. A Hu.manity exibe as informações para o paciente, porém não intermediará o ' +
        'pagamento, que será diretamente para os métodos que você cadastrou.',
      collapsed: false,
    },
    {
      id: 'collapse03',
      title: 'Por que é mais seguro realizar teleconsultas usando a Hu.manity?',
      content:
        'Na Hu.manity, os fluxos de trabalho e os processos de atendimento e administrativos na jornada ' +
        'do paciente estão automatizados e integrados, assim como o prontuário e as prescrições realizadas. ' +
        'Além disso, a plataforma garante a segurança das informações, o sigilo e o fácil acesso das pessoas ' +
        'autorizadas, além de garantir que a prática da telemedicina esteja alinhada às determinações do CFM.' +
        'Ao usar outras plataformas que não são específicas para a prática da telemedicina, além de não contar ' +
        'com a ajuda da automação dos fluxos e processos, você ainda precisa se preocupar com os termos de ' +
        'consentimento do paciente e o armazenamento seguro de tais dados.' +
        'A Hu.manity traz mais conveniência, eficiência e engajamento sem perda de qualidade, segurança e resultados.',
      collapsed: false,
    },
    {
      id: 'collapse04',
      title: 'Posso utilizar a Hu.manity também para consultas físicas?',
      content:
        'Sim. Você pode integrar toda a sua agenda, prontuários e prescrições na plataforma para um acompanhamento ' +
        'total, contínuo e integrado on e offline do seu paciente.',
      collapsed: false,
    },
  ];
  height: number;
  width: number;

  constructor() {}

  ngOnInit(): void {
    this.detectScreenSize();
  }

  public open(event, pergunta) {
    pergunta.collapsed = !pergunta.collapsed;
  }

  @HostListener('window:resize', [])
  public onResize() {
    this.detectScreenSize();
  }

  private detectScreenSize() {
    this.height = window.innerHeight;
    this.width = window.innerWidth;
  }
}
