<section class="conheca" id="conheca">
  <img
  src="assets/images/cinco-watch.svg"
  class="img-fluid"
  alt="pulseira"
  />
  <div class="container">
    <div class="row">
      <div class="coluna-1 col-sm-12 col-md-6">
      </div>
      <div class="coluna-2 col-sm-12 col-md-6 align-middle">

        <img
        src="assets/images/cinco-watch.svg"
        class="img-fluid"
        alt="pulseira"
        />

        <h6>Conheça</h6>

        <h2>Simplifique a Prevenção e o Engajamento do Paciente com a CINCO</h2>

        <p>
          A CINCO é uma solução completa de <b>saúde preventiva</b> que combina tecnologia de ponta com dispositivos vestíveis. Agora, sua empresa pode utilizar o software da CINCO junto com dispositivos integrados, enquanto o atendimento humanizado da equipe de enfermagem pode ser realizado pelos seus profissionais ou dos nossos parceiros.

        </p>

        <p>
          Acompanhar desfechos clínicos, reduzir sinistros e engajar pacientes nunca foi tão fácil.
        </p>

        <button (click)="scrollToFooter()">
          Solicitar agora
        </button>

          </div>
        </div>
      </div>
    </section>
