<section class="ajuste" id="ajuste">
  <div class="content">
    <header>
      <h1>5</h1>
      <h2>Ajuste da pulseira</h2>
      <span>Como ajustar da melhor forma a pulseira no braço:</span>
    </header>
    <section>
      <img
        src="../../../../assets/images/ilustracoes/etapas/pulseira-ajuste.svg"
        alt="Imagem da abertura da pulseira"
      />
      <div class="instructions">
        <div class="instruction-card">
          <span>
            Melhor lugar para utilizar a pulseira é atrás do
            <b>“ossinho do punho”</b> (Borda Ulnar do punho).
          </span>
        </div>
        <div class="instruction-card">
          <span> Ajuste o tamanho adequado ao seu punho. </span>
        </div>
        <div class="instruction-card">
          <span>
            O sensor deve estar próximo à pele, evitando que ele se mova.
          </span>
        </div>
      </div>
    </section>
  </div>
</section>
