import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PerguntasComponent } from './perguntas.component';
import { CtaInscricaoModule } from 'src/app/shared/cta-inscricao/cta-inscricao.module';



@NgModule({
  declarations: [
    PerguntasComponent
  ],
  imports: [
    CommonModule,
    CtaInscricaoModule
  ],
  exports: [
    PerguntasComponent
  ]
})
export class PerguntasModule { }
