import { Component, HostListener, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { api } from 'src/services/api';

@Component({
  selector: 'app-formulario',
  templateUrl: './formulario.component.html',
  styleUrls: ['./formulario.component.scss'],
})
export class FormularioComponent implements OnInit {

  formulario: FormGroup;
  captchaResponse: string | null = null;

  recaptchaSiteKey = environment.recaptchaSiteKey;

  constructor(private fb: FormBuilder) {
    this.formulario = this.fb.group({
      nome: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.email]],
      whatsapp: ['', [Validators.required, Validators.pattern('^[0-9]{10,11}$')]],
      empresa: ['', [Validators.required]]
    });

  }

  handleCaptchaResponse(response: string): void {
    this.captchaResponse = response;
  }

  async onSubmit(): Promise<void> {

    if (!this.captchaResponse) {
      alert("Por favor, resolva o reCAPTCHA primeiro.");
      return;
    }

    if (this.formulario.valid) {

      await api.post('/externalContact/sendUserRequestEmail', {
        name: this.formulario.value.nome,
        company: this.formulario.value.empresa,
        whatsapp: this.formulario.value.whatsapp,
        email: this.formulario.value.email
      },
      {
        headers: {
          'recaptcha-token': this.captchaResponse
        }
      }
    )

    alert("Solicitação enviada.")

    } else {
      console.error('Form is invalid');
    }

  }

  ngOnInit(): void {
    this.detectScreenSize();
  }

  @HostListener('window:resize', [])
  public onResize() {
    this.detectScreenSize();
  }

  private detectScreenSize() {
  }
}
