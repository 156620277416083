import { Component, OnInit } from '@angular/core';
import { Instruction } from '../band-handbook.component.types';

@Component({
  selector: 'app-primeiros-passos',
  templateUrl: './primeiros-passos.component.html',
  styleUrls: ['./primeiros-passos.component.scss'],
})
export class PrimeirosPassosComponent implements OnInit {
  instructions: Instruction[] = [];
  instructionIndex = 0;

  goToNextSection() {
    this.animate(true);

    setTimeout(() => {
      if (this.instructionIndex === this.instructions.length - 1) {
        return;
      }

      this.instructionIndex += 1;

      this.animate(false);
    }, 330);
  }

  goToPreviousSection() {
    this.animate(true);

    setTimeout(() => {
      if (this.instructionIndex === 0) {
        return;
      }

      this.instructionIndex -= 1;
      this.animate(false);
    }, 330);
  }

  animate(activated: boolean) {
    const element = document.getElementById('instruction-card');

    if (!element) {
      return;
    }

    if (activated) {
      element.classList.add('fadeOut');
      element.classList.remove('fadeIn');
    } else {
      element.classList.add('fadeIn');
      element.classList.remove('fadeOut');
    }
  }

  constructor() {}

  ngOnInit(): void {
    this.instructions = [
      {
        stepNumber: 1,
        description:
          'Verifique no verso do visor do relógio a indicação do lado onde a porta USB se encontra.',
      },
      {
        stepNumber: 2,
        description:
          'Puxe a alça do relógio e você verá a porta USB de carregamento.',
      },
      {
        stepNumber: 3,
        description:
          'Encaixe a porta USB do relógio em uma entrada correspondente (por exemplo, uma porta USB do computador ou de carregador de celular). Enquanto carrega, o relógio iniciará automaticamente no modo de carregamento.',
      },
      {
        stepNumber: 4,
        description:
          'Quando a barra de carregamento do relógio estiver completa, retire-o da entrada USB e encaixe a alça de volta novamente.',
      },
    ];
  }
}
