<section class="perguntas" id="perguntas">
  <div class="container">
    <div class="col1">
      <img src="../../../../assets/images/icones/duvidas.svg" alt="" />
      <h1 class="text-center">Perguntas Frequentes</h1>
    </div>
    <div class="col2">
      <div class="duvida">
        <button (click)="changeQuestionDisplayStatus(1)">
          <img
            src="../../../../assets/images/icones/{{
              activeQuestionIndex === 1 ? 'menos-preto' : 'mais-preto'
            }}.svg"
            alt="Ícone do símbolo de {{
              activeQuestionIndex === 1 ? 'colapso' : 'expansão'
            }} da pergunta"
          />
          <strong>
            Não consigo encontrar a pulseira na hora de vincular pelo aplicativo
          </strong>
        </button>
        <p class="{{ activeQuestionIndex === 1 ? 'active' : 'inactive' }}">
          Para que a conexão com a pulseira funcione corretamente, é necessário
          que o GPS e o Bluetooth estejam ativos e conectados com a pulseira,
          conforme o seu modelo.
        </p>
      </div>
      <div class="duvida">
        <button (click)="changeQuestionDisplayStatus(2)">
          <img
            src="../../../../assets/images/icones/{{
              activeQuestionIndex === 2 ? 'menos-preto' : 'mais-preto'
            }}.svg"
            alt="Ícone do símbolo de {{
              activeQuestionIndex === 2 ? 'colapso' : 'expansão'
            }} da pergunta"
          />
          <strong>O aplicativo não está trazendo os dados da pulseira</strong>
        </button>
        <p class="{{ activeQuestionIndex === 2 ? 'active' : 'inactive' }}">
          A pulseira está ligada? Para que a conexão com a pulseira funcione
          corretamente, é necessário que o GPS e o Bluetooth estejam
          conectados.Se ainda assim você não consegue conectar, entre em contato
          com o suporte técnico.
        </p>
      </div>
      <div class="duvida">
        <button (click)="changeQuestionDisplayStatus(3)">
          <img
            src="../../../../assets/images/icones/{{
              activeQuestionIndex === 3 ? 'menos-preto' : 'mais-preto'
            }}.svg"
            alt="Ícone do símbolo de {{
              activeQuestionIndex === 3 ? 'colapso' : 'expansão'
            }} da pergunta"
          />
          <strong>A pulseira é à prova d’água?</strong>
        </button>
        <p class="{{ activeQuestionIndex === 3 ? 'active' : 'inactive' }}">
          Sim, com especificação ip67, que resiste a uma quantidade pequena de
          água. No entanto, nós não recomendamos o mergulho em piscina ou praia
          com a sua pulseira.
        </p>
      </div>
      <div class="duvida">
        <button (click)="changeQuestionDisplayStatus(4)">
          <img
            src="../../../../assets/images/icones/{{
              activeQuestionIndex === 4 ? 'menos-preto' : 'mais-preto'
            }}.svg"
            alt="Ícone do símbolo de {{
              activeQuestionIndex === 4 ? 'colapso' : 'expansão'
            }} da pergunta"
          />
          <strong
            >O Bluetooth do meu celular precisa estar ativo o tempo
            todo?</strong
          >
        </button>
        <p class="{{ activeQuestionIndex === 4 ? 'active' : 'inactive' }}">
          Quando o bluetooth do seu smartphone está desligado nós perdemos a
          informação em tempo real dos seus sinais de saúde, mas quando ele
          volta a ser ativado, os dados são automaticamente sincronizados. Por
          isso, para um serviço mais seguro, aconselhamos que o seu bluetooth
          permaneça sempre ativo.
        </p>
      </div>
      <div class="duvida">
        <button (click)="changeQuestionDisplayStatus(5)">
          <img
            src="../../../../assets/images/icones/{{
              activeQuestionIndex === 5 ? 'menos-preto' : 'mais-preto'
            }}.svg"
            alt="Ícone do símbolo de {{
              activeQuestionIndex === 5 ? 'colapso' : 'expansão'
            }} da pergunta"
          />
          <strong>Os valores das medições estão sempre corretos?</strong>
        </button>
        <p class="{{ activeQuestionIndex === 5 ? 'active' : 'inactive' }}">
          Os valores aferidos são apenas para referência pessoal, não devem,
          portanto, ser utilizado como parâmetro médico. Cada valor de medição
          possui uma variação de aproximadamente 5% para mais ou para menos.
        </p>
      </div>
    </div>
  </div>
</section>
