<section class="humanizacao" id="humanizacao">
  <div class="container">
    <div class="row">
      <div class="coluna-1 col-sm-12 col-lg-6">
        <h6>Humanização</h6>

        <h2>Nenhuma tecnologia substitui o poder da conexão humana</h2>

        <p>Você deve estar se perguntando por que não usamos inteligências artificiais para se comunicar com as pessoas.</p>

      <p>
        Acreditamos que há algumas coisas que só podem ser alcançadas através da interação humana direta e empática.
        A CINCO é composta pela perfeita combinação entre tecnologia de ponta e o toque humano dos nossos profissionais de saúde, que são treinados para atender todas as pessoas com empatia e o máximo de respeito. Entendemos que pessoas precisam de pessoas e, aprendemos que, principalmente em situações dificeis, o atendimento humanizado faz toda a diferença.
      </p>
      </div>

      <div class="coluna-2 col-sm-12 col-lg-6">
        <img
          src="assets/images/humanizacao.png"
          class="img-fluid"
          alt="DNA da Hu.manity"
        />
      </div>
    </div>
  </div>
</section>
