<section class="vincule" id="vincule">
  <div class="content">
    <header>
      <h1>3</h1>
      <h2>Vincule o aplicativo e a pulseira de saúde</h2>
      <span
        >Para esse processo, mantenha a pulseira de saúde próxima ao
        celular.</span
      >
    </header>
    <section>
      <img
        src="../../../../assets/images/ilustracoes/etapas/pulseira-celular-conexao.svg"
        alt="Imagem da abertura da pulseira"
      />
      <div class="instructions">
        <div class="steps-controller">
          <button
            [disabled]="instructionIndex === 0"
            (click)="goToPreviousSection()"
          >
            <img
              *ngIf="instructionIndex > 0"
              src="../../../../assets/images/icones/left-arrow.svg"
            />
            <img
              *ngIf="instructionIndex === 0"
              src="../../../../assets/images/icones/disabled-left-arrow.svg"
            />
          </button>
          <span
            ><b>{{ instructionIndex + 1 }}</b> de
            {{ instructions.length }}</span
          >
          <button
            [disabled]="instructionIndex === instructions.length - 1"
            (click)="goToNextSection()"
          >
            <img
              *ngIf="instructionIndex < instructions.length - 1"
              src="../../../../assets/images/icones/right-arrow.svg"
            />
            <img
              *ngIf="instructionIndex === instructions.length - 1"
              src="../../../../assets/images/icones/disabled-right-arrow.svg"
            />
          </button>
        </div>
        <div id="instruction-section">
          <article *ngFor="let instruction of instructions[instructionIndex]">
            <strong>{{ instruction.stepNumber }}º</strong>
            <span> {{ instruction.description }} </span>
          </article>
        </div>
      </div>
    </section>
  </div>
</section>
