<section id="formulario" class="formulario">
  <div class="container">
   <div class="col">
    <div class="partnership-container">
     <h2>PARCEIROS:</h2>
     <div class="logo-container">
      <img
        src="{{ item.image }}"
        alt="{{ item.alt }}"
        *ngFor="let item of items; let i = index"
      />
     </div>
    </div>
    <div class="partnership-container-mobile">
      <div id="carouselParceiros" class="carousel slide" data-ride="carousel">
        <ol class="carousel-indicators">
          <li
            data-target="#carouselParceiros"
            data-slide-to="0"
            class="active"
          ></li>
          <li data-target="#carouselParceiros" data-slide-to="1"></li>
          <li data-target="#carouselParceiros" data-slide-to="2"></li>
          <li data-target="#carouselParceiros" data-slide-to="3"></li>
        </ol>
        <div class="carousel-inner">
          <div
            class="carousel-item"
            *ngFor="let item of items; let isFirst = first"
            [ngClass]="{ active: isFirst }"
          >
          <div class="d-flex justify-content-center align-items-center" style="height: 100px;">
            <img src="{{ item.image }}" alt="{{ item.alt }}" class="img-fluid" />
          </div>
          </div>
        </div>
      </div>
    </div>

   </div>
  </div>
</section>
