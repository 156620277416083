<app-header [type]="type"></app-header>

<section id="terms-use" class="terms-use mt-5 pt-5">
    <div class="container">
        <div class="row session-head">
            <div class="col-lg-12 text-center">
                <h2 class="session-title">
                  Termos e Política de Privacidade
                </h2>
            </div>
        </div>

        <div class="row">
            <img src="assets\images\ilustracoes\termos-de-uso.svg">
        </div>

        <div class="row">
          <span class="atualizacao">Atualização: 02/07/2021</span>
        </div>

        <div class="row">
            <div class="col-12">
                <p>
                  O CINCO está comprometido com a transparência a seus usuários e visitantes com relação às informações coletadas e utilizadas em nosso site, para proporcionar uma melhor experiência de navegação. Assim, apresentamos nossos termos de uso e política de privacidade, que tem por objetivo esclarecer o uso dessas informações
                </p>

                <h4>Identificação</h4>
                <p>
                  Este site é de propriedade, mantido e operado pela HU INNOVATION CNPJ 38.570.443/0001-90
                </p>
                <p>
                  Caso o usuário necessite de qualquer informação, esclarecimento ou atendimento com relação a esta Política de Privacidade e Segurança, a HU, através do CINCO, disponibiliza o seguinte e-mail:
                </p>
                <p>
                  suporte@saudecinco.com
                </p>

                <h4>
                  Consentimento
                </h4>
                <p>
                  Ao compartilhar seus dados, você concorda com as práticas descritas nesta política de privacidade.
                </p>

                <h5>
                  Da coleta de dados
                </h5>
                <p>
                  Em nossas plataformas e formulários, as informações podem ser coletadas das seguintes formas:
                </p>
                <p>
                  <b>Informações fornecidas no cadastro:</b>
                  Coletamos informações de identificação pessoal – como: Seu nome, Cargo, Empresa, Telefone ou Whatsapp, sua Cidade e Estado, a quantidade de assinaturas que você precisa dos nossos serviços e a descrição das suas necessidades.
                </p>
                <p>
                  <b>Informações de navegação no site: </b>
                  Quando o usuário visita nossa plataforma, são coletadas informações como endereço IP e Cookies para uma identificação segura no acesso ao banco de dados baseado em tokenização.
                </p>
                <p>
                  <b>Outras fontes:</b>
                  podemos coletar informações específicas de dispositivos para gerenciamento eficiente e seguro da plataforma. Seus dados citados acima, também são compartilhados com o Google Analytics, para um gerenciamento de usuários.
                </p>
                <p>
                  Eventualmente, algumas informações podem ser feitas por meio de contato direto da equipe CINCO com os usuários via e-mail ou telefone.
                </p>

                <h5>
                  Da proteção dos dados
                </h5>
                <p>
                  Todas as comunicações entre o cliente e nossos servidores são executadas com a tecnologia SSL (Secure Socket Layer), garantindo que todos os dados pessoais do cliente, entre outros, jamais sejam divulgados. Além disso, essa tecnologia visa impedir que as informações sejam transmitidas ou acessadas por terceiros.
                </p>
                <p>
                  Todos os dados pessoais informados ao nosso site são armazenados em um banco de dados reservado e com acesso restrito a pessoas habilitadas, que são obrigadas, por contrato, a manter a confidencialidade das informações e não utilizá-las inadequadamente.
                </p>

                <h5>
                  Registro de acesso
                </h5>
                <p>
                  A HU, através do CINCO, manterá em sua base de dados todas as informações relativas aos acessos do Usuário ao Site, incluindo, mas não se limitando ao endereço IP, às páginas acessadas, aos horários e datas de acesso, e o dispositivo de acesso utilizado, nos termos da legislação vigente. Tais registros poderão ser utilizados em investigações internas ou públicas para averiguação de práticas que possam gerar quaisquer prejuízos à HU, inclusive a prática de crimes em ambientes virtuais.
                </p>

                <h5>
                  Guarda dos dados
                </h5>
                <p>
                  A HU, através do CINCO, guardará todos os dados coletados em suas bases de dados protegidas e seguras. Tais dados serão acessados apenas por processos computadorizados automatizados, profissionais autorizados e nos casos listados nesta Política. Caso o Usuário requeira a exclusão de seus dados da base de dados, a HU se reserva o seu direito de manter os dados em questão em cópias de salvaguarda por até 6 (seis) meses, a fim de cumprir obrigações legais do compartilhamento de informações A segurança e o sigilo das informações de nossos clientes são muito importantes para o nosso negócio. Porém, a viabilidade de certos serviços pelos nossos parceiros só ocorre com o compartilhamento de algumas dessas informações, o que fazemos com responsabilidade e seguindo todos os parâmetros registrados nesta Política de Privacidade. Abaixo, citamos os casos onde o compartilhamento de informações se faz necessário:
                </p>
                <p>
                  <b>
                    Fornecedores:
                  </b>
                  Empresas fornecedoras e terceiros trabalham com o CINCO para aprimorar nosso negócio. São exemplos os serviços logísticos dos pedidos, envio de e-mails, análise da base de dados, criação de ações de marketing. Muitos desses serviços só são possíveis com o compartilhamento de informações de nossos clientes, porém, é importante reforçar que seu uso está autorizado apenas para as finalidades contratadas.
                </p>
                <p>
                  <b>
                    Novos negócios:
                  </b>
                  no contínuo desenvolvimento do nosso negócio, processos de aquisição e fusão de empresas, subsidiárias e outros negócios podem ocorrer. Nessa transferência de negócios, informações dos respectivos clientes também são transferidas, mas ainda assim, será mantida a política de privacidade, e a informação será mantida dentro do ambiente HU.
                </p>
                <p>
                  Requisição Judicial: Compartilhar dados pessoais em caso de requisição judicial. Com a autorização do cliente: em demais casos, havendo a necessidade de compartilhamento das informações, enviaremos ao cliente uma notificação solicitando sua aprovação ou reprovação.
                </p>

                <h4>
                  Da aplicação da presente política
                </h4>
                <p>
                  A presente política de privacidade aplica-se a todos os serviços e sites oferecidos pela HU, através do CINCO.
                </p>
                <p>
                  Links de terceiros eventualmente incluídos no site, têm políticas de privacidade independentes.
                </p>
                <p>
                  Alterações da política de privacidade serão publicadas nesta página. Desta forma, recomendamos visitar periodicamente esta página para que você tenha conhecimento sobre as modificações.
                </p>
            </div>
        </div>
    </div>
</section>

<app-footer></app-footer>
