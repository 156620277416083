import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { VinculeComponent } from './vincule.component';

@NgModule({
  declarations: [VinculeComponent],
  imports: [CommonModule],
  exports: [VinculeComponent],
})
export class VinculeModule {}
