export class Orcamento {
  name: string;
  email: string;
  company?: string;
  role?: string;
  phone: string;
  numberEmployees: string;
  cityAndUf: string;
  necessity?: string;

  public constructor(orcamentoInit?: Partial<Orcamento>) {
    Object.assign(this, orcamentoInit);
  }
}
