import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ParaQuemComponent } from './para-quem.component';

@NgModule({
  declarations: [ParaQuemComponent],
  imports: [CommonModule],
  exports: [ParaQuemComponent],
})
export class ParaQuemModule {}
