import { Component, HostListener, OnInit } from '@angular/core';

@Component({
  selector: 'app-cuidado',
  templateUrl: './cuidado.component.html',
  styleUrls: ['./cuidado.component.scss'],
})
export class CuidadoComponent implements OnInit {
  cards = [
    {
      title: 'Maior engajamento do paciente na adesão aos tratamentos de saúde',
      image: 'assets/images/ilustracoes/cuidado-1.svg',
    },
    {
      title: 'Facilidade no acompanhamento de desfechos clínicos',
      image: 'assets/images/ilustracoes/cuidado-5.svg',
    },
    {
      title: 'Efetividade na saúde preventiva com resultados em tempo real',
      image: 'assets/images/ilustracoes/cuidado-3.svg',
    },
    {
      title: 'Redução de custos com emergências e tratamentos complexos',
      image: 'assets/images/ilustracoes/cuidado-6.svg',
    },
  ];
  height: number;
  width: number;

  constructor() {}

  scrollToFooter(): void {
    const footerElement = document.getElementById('formulario');
    if (footerElement) {
      footerElement.scrollIntoView({ behavior: 'smooth' });
    }
  }

  ngOnInit(): void {
    this.detectScreenSize();
  }

  @HostListener('window:resize', [])
  public onResize() {
    this.detectScreenSize();
  }

  private detectScreenSize() {
    this.height = window.innerHeight;
    this.width = window.innerWidth;
  }
}
