<section id="fqa" class="fqa">
  <div class="container">
   <div class="col">
    <div class='fqa-container'>
      <div class="title-container">
        <h1>PERGUNTAS FREQUENTES</h1>
      </div>

      <div class="title-container-mobile">
        <h1>PERGUNTAS <br/>FREQUENTES</h1>
      </div>
      <div class="question-container"
        *ngFor="let question of questions; let i = index">
        <h1>{{ question.title }}</h1>
        <p>{{ question.answer }}</p>
      </div>
    </div>
   </div>
  </div>

</section>
