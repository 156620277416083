import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ComoFuncionaComponent } from './como-funciona.component';

@NgModule({
  declarations: [ComoFuncionaComponent],
  imports: [CommonModule],
  exports: [ComoFuncionaComponent],
})
export class ComoFuncionaModule {}
