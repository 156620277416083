import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TermsUseComponent } from './terms-use.component';
import { HeaderModule } from 'src/app/shared/header/header.module';
import { FooterModule } from 'src/app/shared/footer/footer.module';

@NgModule({
  declarations: [
    TermsUseComponent
  ],
  imports: [
    CommonModule,
    HeaderModule,
    FooterModule
  ],
  exports: [
    TermsUseComponent
  ]
})
export class TermsUseModule { }
