import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ApresentacaoComponent } from './apresentacao.component';

@NgModule({
  declarations: [ApresentacaoComponent],
  imports: [CommonModule],
  exports: [ApresentacaoComponent],
})
export class ApresentacaoModule {}
