<section id="preco" class="preco">
  <div class="container">
    <div class="row">
      <div class="coluna-1 col-md-12 col-lg">
        <h2>
          Planos<br />
          & Preços
        </h2>
        <p>
          Selecione a quantidade de<br />assinaturas que você precisa:
          <i class="fa fa-question-circle" aria-hidden="true"></i>
        </p>
        <div class="botoes">
          <img src="assets/images/icones/menos.svg" (click)="decrement()" />
          <div class="quantidade">
            <span>{{ quantidade }}</span>
          </div>
          <img src="assets/images/icones/mais.svg" (click)="increment()" />
        </div>
        <input
          class="barra"
          type="range"
          id="assinaturas"
          min="1"
          max="{{ planos.length }}"
          value="{{ quantidade }}"
          (change)="changeSlider()"
        />
      </div>
      <div class="coluna-2 col-md-12 col-lg">
        <div class="opcoes">
          <span class="anual" (click)="changePlan('anual')">Anual</span>
          <span class="mensal" (click)="changePlan('mensal')">Mensal</span>
        </div>
        <div class="preco">
          <h1 class="valor">{{ planos[quantidade - 1].preco }}</h1>
          <span *ngIf="quantidade < planos.length">/mês</span>
        </div>
        <p *ngIf="tipo === 'anual'">
          {{ planos[quantidade - 1].economia }}
        </p>
        <p *ngIf="tipo === 'mensal'">Cancele sem custos quando quiser</p>
        <a
          class="botao-consultores"
          data-toggle="modal"
          data-target="#modal"
          *ngIf="quantidade == planos.length"
        >
          Contate nossos<br />
          consultores
        </a>
        <a
          class="btn-outline-lg"
          href="https://app.humanity.healthcare/register"
          target="_blank"
          rel="noopener noreferrer"
          >Experimente grátis</a
        >
        <span>Por um período de 3 meses</span>
      </div>
      <div class="coluna-3 col-md-12 col-lg-5">
        <h5>O que está incluso?</h5>
        <ul>
          <li *ngFor="let item of itens">
            <img src="assets/images/icones/check-2.svg" />
            <p>{{ item }}</p>
          </li>
        </ul>
      </div>
    </div>
  </div>
  <div class="modal fade" id="modal" tabindex="-1">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="botao-fechar">
          <a class="close" data-dismiss="modal" aria-label="Close"
            ><img src="assets/images/icones/botao-fechar.svg"
          /></a>
        </div>
        <div class="texto">
          <h4>Receba um orçamento via e-mail</h4>
          <p>
            Nossa equipe de consultores irá avaliar a sua solicitação e, muito
            em breve, você receberá o valor por e-mail.
          </p>
        </div>
        <form action="">
          <div class="obrigatorio">
            <input
              type="text"
              name="nome"
              id="nome"
              placeholder="Nome"
              required
            />
            <span>*</span>
          </div>
          <div class="obrigatorio">
            <input
              type="email"
              name="email"
              id="email"
              placeholder="E-mail"
              required
            />
            <span>*</span>
          </div>
          <input type="tel" name="tel" id="tel" placeholder="Celular" />
          <p>Você é clínica ou hospital?</p>
          <div class="opcoes">
            <input type="radio" id="clinica" name="tipo" value="clinica" />
            <label for="clinica">Sim, sou clínica</label>
          </div>
          <div class="opcoes">
            <input type="radio" id="hospital" name="tipo" value="hospital" />
            <label for="hospital">Sim, sou hospital</label>
          </div>
          <div class="opcoes">
            <input type="radio" id="outro" name="tipo" value="outro" />
            <label for="outro">Não</label>
          </div>
          <button class="btn-solid-lg" type="submit">Enviar</button>
        </form>
      </div>
    </div>
  </div>
</section>
