import { Component, OnInit, HostListener } from '@angular/core';

@Component({
  selector: 'app-apresentacao',
  templateUrl: './apresentacao.component.html',
  styleUrls: ['./apresentacao.component.scss'],
})
export class ApresentacaoComponent implements OnInit {
  height: number;
  width: number;

  constructor() {}

  ngOnInit(): void {
    this.detectScreenSize();
  }

  @HostListener('window:resize', [])
  public onResize() {
    this.detectScreenSize();
  }

  public scrollToSection(id: string) {
    if (id) {
      window.scroll({
        top: $(id).offset().top - 142,
        left: 0,
        behavior: 'smooth',
      });
    }
  }

  private detectScreenSize() {
    this.height = window.innerHeight;
    this.width = window.innerWidth;
  }
}
