import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IdealParaComponent } from './ideal-para.component';

@NgModule({
  declarations: [IdealParaComponent],
  imports: [CommonModule],
  exports: [IdealParaComponent],
})
export class IdealParaModule {}
