import { Component, HostListener, OnInit } from '@angular/core';

@Component({
  selector: 'app-legislacao',
  templateUrl: './legislacao.component.html',
  styleUrls: ['./legislacao.component.scss'],
})
export class LegislacaoComponent implements OnInit {
  height: number;
  width: number;
  cards = [
    {
      id: 'collapse01',
      text: 'Ver Mais',
      collapsed: false,
    },
    {
      id: 'collapse02',
      text: 'Ver Mais',
      collapsed: false,
    },
  ];

  constructor() {}

  ngOnInit(): void {
    this.detectScreenSize();
  }

  @HostListener('window:resize', [])
  public onResize() {
    this.detectScreenSize();
  }

  private detectScreenSize() {
    this.height = window.innerHeight;
    this.width = window.innerWidth;
  }

  public open(event, card) {
    card.collapsed ? (card.text = 'Ver Mais') : (card.text = 'Ver Menos');
    card.collapsed = !card.collapsed;
  }
}
