import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-beneficios',
  templateUrl: './beneficios.component.html',
  styleUrls: ['./beneficios.component.scss'],
})
export class BeneficiosComponent implements OnInit {
  beneficios = [
    'Prontuário habilitado para telemedicina em nuvem',
    'Agendamento online',
    'Backup de prontuários',
    'Integração com a Memed para prescrição digital',
    'Atenda em qualquer lugar: Plataforma disponível para smartphones',
    'Maior engajamento dos pacientes',
    'Termo de consentimento livre e esclarecido automatizado',
  ];

  constructor() {}

  ngOnInit(): void {}
}
