<section id="planos" class="planos">
  <div class="container text-center">
    <div class="row">
      <div class="text-col">
        <h2>Planos</h2>
        <strong>
          Condições especiais de lançamento
        </strong>

        <p>As condições ao lado se referem às ofertas de lançamento, referentes ao nosso primeiro lote e estão sujeitas às limitações de estoque.</p>

        <div class="text-col-row">
          <p>Estoque do primeiro lote: </p>
          <span class="store-available">30</span>
        </div>
      </div>

      <div
        class="card col card-desktop"
        *ngFor="let card of cards; let i = index"
      >
        <div class="badge-top" *ngIf="card.key !== 'enterprise'">
          <strong>{{ card.key === 'monthly' ? '-53%' : 'Melhor oferta!' }}</strong>
        </div>

        <h5>{{ card.title }}</h5>
        <ul>
          <li
            *ngFor="let benefit of card.benefits; let i = index"
          >
            <img
              src="assets/images/icones/check-2.svg"
              alt="check-icon"
            />

            <p>{{ benefit }}</p>
          </li>
        </ul>

        <div class="monthly-prices" *ngIf="card.key !== 'enterprise'">
          <strong>R$ {{ card.originalPrice }}</strong> <p>/mês</p>
        </div>

        <div class="monthly-prices" *ngIf="card.key !== 'enterprise'">
          <strong class="with-discount">R$ {{card.priceWithDiscount}}</strong> <p>/mês</p>
        </div>

        <div class="membership-fee" *ngIf="card.key !== 'enterprise'">
          <span
          *ngIf="card.key === 'monthly'"
          >
            Adesão: R$ 99,00
          </span>

          <span *ngIf="card.key === 'semestral'">
          Adesão grátis
          </span>
        </div>

        <p
          *ngIf="card.key === 'enterprise'"
          id="enterprise-targeted"
        >
          Ideal para operadoras de saúde, gestoras de benefícios, casas de repouso, secretarias de saúde.
        </p>

        <p class="tip">
          {{ card.tip }}
        </p>

        <a
          class="btn-solid-lg btn-solid-lg-primary"
          target="_blank"
          href="https://api.whatsapp.com/send?phone={{ whatsappContactPhone }}&text={{ card.whatsappMessageToSend }}"
        >
          {{ card.key === 'enterprise' ? 'Consulte' : 'Selecionar' }}
        </a>
      </div>

      <div class="row row-tab-bar">
        <div class="tab-bar">
          <div 
            class="tab" 
            *ngFor="let card of cards; index as i"
            [ngClass]="{ 'selected' : card.selected }" 
            (click)="selectCard(card.key)"
          >
            <button type="button">{{ card.title }}</button>
          </div>
        </div>
      </div>
  
      <div class="row row-card-col">
        <div
          class="card col"
        >
          <div class="badge-top" *ngIf="selectedCard.key !== 'enterprise'">
            <strong>{{ selectedCard.key === 'monthly' ? '-53%' : 'Melhor oferta!' }}</strong>
          </div>
  
          <h5>{{ selectedCard.title }}</h5>
          <ul>
            <li
              *ngFor="let benefit of selectedCard.benefits; let i = index"
            >
              <img
                src="assets/images/icones/check-2.svg"
                alt="check-icon"
              />
  
              <p>{{ benefit }}</p>
            </li>
          </ul>
  
          <div class="monthly-prices" *ngIf="selectedCard.key !== 'enterprise'">
            <strong>R$ {{ selectedCard.originalPrice }}</strong> <p>/mês</p>
          </div>
  
          <div class="monthly-prices" *ngIf="selectedCard.key !== 'enterprise'">
            <strong class="with-discount">R$ {{selectedCard.priceWithDiscount}}</strong> <p>/mês</p>
          </div>
  
          <div class="membership-fee" *ngIf="selectedCard.key !== 'enterprise'">
            <span
            *ngIf="selectedCard.key === 'monthly'"
            >
              Adesão: R$ 99,00
            </span>
  
            <span *ngIf="selectedCard.key === 'semestral'">
            Adesão grátis
            </span>
          </div>
  
          <p
            *ngIf="selectedCard.key === 'enterprise'"
            id="enterprise-targeted"
          >
            Ideal para operadoras de saúde, gestoras de benefícios, casas de repouso, secretarias de saúde.
          </p>
  
          <p class="tip">
            {{ selectedCard.tip }}
          </p>
  
          <a
            class="btn-solid-lg btn-solid-lg-primary"
            target="_blank"
            href="https://api.whatsapp.com/send?phone={{ whatsappContactPhone }}&text={{ selectedCard.whatsappMessageToSend }}"
          >
            {{ selectedCard.key === 'enterprise' ? 'Consulte' : 'Selecionar' }}
          </a>
        </div>  
      </div>
      
    </div>

    <!-- <div class="row">
      <div class="text-col">
        <h2>Planos</h2>

        <strong>
          Condições especiais de lançamento
        </strong>

        <p>As condições ao lado se referem às ofertas de lançamento, referentes ao nosso primeiro lote e estão sujeitas às limitações de estoque.</p>

        <div class="text-col-row">
          <p>Estoque do primeiro lote: </p>
          <span class="store-available">30</span>
        </div>
      </div>      
    </div> -->


    
  </div>
</section>
