import { NgxMaskModule, IConfig } from 'ngx-mask';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OrcamentoComponent } from './orcamento.component';
import { ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { SharedModule } from 'src/app/shared/shared.module';
import { OrcamentoService } from './orcamento.service';
import { ModalOrcamentoSolicitadoModule } from './modal-orcamento-solicitado/modal-orcamento-solicitado.module';


export const options: Partial<IConfig> | (() => Partial<IConfig>) = null;

@NgModule({
  declarations: [
    OrcamentoComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    HttpClientModule,
    SharedModule,
    NgxMaskModule.forRoot(),
    ModalOrcamentoSolicitadoModule,
  ],
  providers: [OrcamentoService],
  exports: [
    OrcamentoComponent
  ]
})
export class OrcamentoModule { }
